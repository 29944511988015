import React, { useContext } from 'react'
import { string } from 'prop-types'

import { RowContext } from '@/component/Context/RowContext'

import Promo from '@/component/Composition/Promo'
import PromoDouble from '@/component/Composition/PromoDouble'
import Container from '@/component/Primitive/Container'

const PromoWidget = ({
  title,
  text,
  ctaLink,
  ctaText,
  titleSecondary,
  textSecondary,
  ctaLinkSecondary,
  ctaTextSecondary,
  titleColor,
  copyColor
}) => {
  const contextRowType = useContext(RowContext)

  return (
    <Container center size="wide" gutter={contextRowType === 'panelled'}>
      <PromoDouble
        primary={
          <Promo
            title={title}
            text={text}
            ctaLink={ctaLink}
            ctaText={ctaText}
            titleColor={titleColor}
            copyColor={copyColor}
            corners
          />
        }
        secondary={
          <Promo
            title={titleSecondary}
            text={textSecondary}
            ctaLink={ctaLinkSecondary}
            ctaText={ctaTextSecondary}
            titleColor={titleColor}
            copyColor={copyColor}
            corners
          />
        }
      />
    </Container>
  )
}

PromoWidget.propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  ctaLink: string.isRequired,
  ctaText: string.isRequired,
  titleSecondary: string.isRequired,
  textSecondary: string.isRequired,
  ctaLinkSecondary: string.isRequired,
  ctaTextSecondary: string.isRequired,
  titleColor: string,
  copyColor: string
}

export default PromoWidget
