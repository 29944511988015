import React, { useContext } from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import Icon from '../Icon'

import { AccentColorContext } from '@/component/Context/AccentColorContext'

import styles from './SuperHero.module.scss'

const SuperHero = ({
  backgroundColor,
  kioskTopLabel,
  kioskTopLink,
  kioskBottomLabel,
  kioskBottomLink,
  leftBuoyLabel,
  leftBuoyLink,
  rightBuoyLabel,
  rightBuoyLink
}) => {
  const bgColor = useContext(AccentColorContext)
  const derivedColor = backgroundColor || '#0ce8ff'

  return (
    <div
      className={styles.SuperHeroWrapper}
      style={{ backgroundColor: `${derivedColor}` }}
    >
      <div className={styles.SuperHero}>
        <div className={styles.SuperHeroContent}>
          <div
            className={classNames(
              styles.bobbingAnimationKiosk,
              styles.SuperHeroMascotPosition
            )}
          >
            <div className={classNames(styles.SuperHeroMascot)}>
              <a
                href={kioskTopLink}
                className={classNames(
                  styles.SuperHeroButton,
                  styles.SuperHeroButton1
                )}
              >
                <span dangerouslySetInnerHTML={{ __html: kioskTopLabel }} />
              </a>
              <a
                href={kioskBottomLink}
                className={classNames(
                  styles.SuperHeroButton,
                  styles.SuperHeroButton2
                )}
              >
                <span dangerouslySetInnerHTML={{ __html: kioskBottomLabel }} />
              </a>
            </div>
          </div>

          {leftBuoyLabel && (
            <div
              className={classNames(
                styles.bobbingAnimation,
                styles.SuperHeroLeftBuoyPosition
              )}
            >
              <div
                className={classNames(
                  styles.SuperHeroLeftBuoy,
                  styles.SuperHeroBuoy
                )}
              >
                <img
                  className={styles.SuperHeroLeftTop}
                  src="/image/SuperHeroTopLeft.svg"
                  alt=""
                />
                <a
                  href={leftBuoyLink}
                  className={classNames(
                    styles.SuperHeroButton,
                    styles.SuperHeroButton3
                  )}
                >
                  <span dangerouslySetInnerHTML={{ __html: leftBuoyLabel }} />
                </a>
                <img alt="" src="/image/SuperHeroBuoyLeft-new.svg" />
              </div>
            </div>
          )}

          {rightBuoyLabel && (
            <div
              className={classNames(
                styles.bobbingAnimation,
                styles.bobbingAnimationAlt,
                styles.SuperHeroRightBuoyPosition
              )}
            >
              <div
                className={classNames(
                  styles.SuperHeroRightBuoy,
                  styles.SuperHeroBuoy
                )}
              >
                <img
                  className={styles.SuperHeroRightTop}
                  src="/image/SuperHeroTopRight.svg"
                  alt=""
                />
                <a
                  href={rightBuoyLink}
                  className={classNames(
                    styles.SuperHeroButton,
                    styles.SuperHeroButton4
                  )}
                >
                  <span dangerouslySetInnerHTML={{ __html: rightBuoyLabel }} />
                </a>
                <img src="/image/SuperHeroBuoyRight-new.svg" alt="" />
              </div>
            </div>
          )}
        </div>
        <Icon
          className={styles.SuperHeroBackgroundTrim}
          type="backgroundTrim"
          color={`${bgColor}`}
          width="auto"
          a11yText=""
        />
      </div>
    </div>
  )
}

SuperHero.propTypes = {
  backgroundColor: string,
  kioskTopLabel: string.isRequired,
  kioskTopLink: string.isRequired,
  kioskBottomLabel: string.isRequired,
  kioskBottomLink: string.isRequired,
  leftBuoyLabel: string,
  leftBuoyLink: string,
  rightBuoyLabel: string,
  rightBuoyLink: string
}

export default SuperHero
