import React from 'react'
import { string, bool, object, func } from 'prop-types'

import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Stack from '@/component/Primitive/Stack'
import TextAlign from '@/component/Primitive/TextAlign'
import Type from '@/component/Primitive/Type'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import CheckControl from '@/component/Primitive/CheckControl'
import Notification from '@/component/Primitive/Notification'
import Rule from '@/component/Primitive/Rule'

import fieldsets from './fieldsets'
import Container from '@/component/Primitive/Container'

const ContactForm = ({
  title,
  formType,
  country,
  error,
  errors,
  register,
  watch,
  loading,
  success,
  onSubmit,
  __url
}) => {
  const Fieldset = fieldsets[formType]
  return (
    <Container center gutter size="medium">
      <Stack>
        <TextAlign center>
          <Type as="h3" size="heading3">
            {title}
          </Type>
        </TextAlign>

        <form onSubmit={onSubmit}>
          <Stack gap="small">
            <input type="hidden" name="formType" value={formType} />
            <input type="hidden" name="country" value={country} />

            <Fieldset
              country={country}
              errors={errors}
              register={register}
              watch={watch}
            />

            <Rule />

            <Stack as="div" gap="large">
              <FieldTemplate
                label="Terms"
                controlName="termsAgreed"
                status={errors.termsAgreed ? 'error' : undefined}
                feedback={errors.termsAgreed && errors.termsAgreed.message}
              >
                <CheckControl type="checkbox" {...register('termsAgreed')}>
                  I agree to the{' '}
                  <a
                    href={__url('/shop/data-privacy-legal/terms-conditions')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    terms and conditions
                  </a>{' '}
                  &{' '}
                  <a
                    href={__url('/shop/data-privacy-legal ')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy policy
                  </a>
                </CheckControl>
              </FieldTemplate>
            </Stack>

            {error && (
              <Stack gap="small">
                <Notification status="error">
                  <Type size="small">{error}</Type>
                </Notification>
              </Stack>
            )}
            {success && (
              <Notification status="success">
                <Type size="small">
                  {formType === 'Hairy Reading - Free Trial' ? (
                    <>
                      Thank you for registering for a 4-month free trial to
                      Hairy Reading. All licenses will be activated, and welcome
                      emails sent by March 15th 2022. Please check your junk
                      mail or contact{' '}
                      {country === 'US' ? 'info@nessy.com' : 'info@nessy.co.uk'}{' '}
                      if you do not receive your welcome email by March 15,{' '}
                      2022.
                    </>
                  ) : (
                    'Thank you for contacting Nessy. We aim to respond within 24 business hours. Office hours are Mon-Fri 9am-5pm.'
                  )}
                </Type>
              </Notification>
            )}

            <ButtonStandard block type="submit" disabled={loading || success}>
              {loading ? 'Loading…' : 'Submit'}
            </ButtonStandard>
          </Stack>
        </form>
      </Stack>
    </Container>
  )
}

ContactForm.propTypes = {
  title: string.isRequired,
  formType: string.isRequired,
  country: string.isRequired,
  loading: bool,
  error: string,
  errors: object.isRequired,
  register: func.isRequired,
  watch: func.isRequired,
  success: bool,
  onSubmit: func.isRequired,
  __url: func.isRequired
}

export default ContactForm
