import React from 'react'
import { array, string } from 'prop-types'

import MediaSwitcher from '@/component/Composition/MediaSwitcher'
import VideoPreview from '@/component/Primitive/VideoPreview'
import VideoProvider from '@/component/Primitive/VideoProvider'

const VideoSwitcherWidget = ({ videos, titleColor, copyColor }) => {
  const getSrcSet = (images) => {
    const getImage = (size) => images?.[`preview_16x9_${size}`]?.[0]
    return [
      {
        width: 0,
        src: getImage(400).url
      },
      {
        width: 400,
        src: getImage(800).url
      },
      {
        width: 800,
        src: getImage(1048).url
      }
    ]
  }
  const getThumbnail = (images) => images?.thumbnail_1x1_200?.[0].url

  const formattedItems = videos.map((item, i) => {
    const {
      images,
      title,
      provider,
      videoId,
      subtitle,
      attributionName,
      attributionCompany
    } = item

    return {
      thumbnail: getThumbnail(images),
      title,
      media: (
        <VideoPreview
          key={`VideoSwitcherPreview:${i}`}
          alt=""
          srcSet={getSrcSet(images)}
          title={title}
          description={subtitle}
          attributionPrimary={attributionName}
          attributionSecondary={attributionCompany}
          titleColor={titleColor}
          copyColor={copyColor}
        >
          <VideoProvider provider={provider} videoId={videoId} />
        </VideoPreview>
      )
    }
  })
  return <MediaSwitcher items={formattedItems} />
}

VideoSwitcherWidget.propTypes = {
  videos: array,
  titleColor: string,
  copyColor: string
}

export default VideoSwitcherWidget
