import React from 'react'
import { array, string } from 'prop-types'

import EmbellishedCardGrid from '@/component/Composition/EmbellishedCardGrid'

const RelatedArticleGrid = ({ articles, title, subtitle }) => {
  const formattedArticles = articles?.map((article) => ({
    id: article._id,
    title: article.headline,
    description: article.sell,
    link: article.fullUrlPath,
    // eslint-disable-next-line camelcase
    src: article.images?.thumbnail_16x9_310?.[0]?.url
  }))

  return (
    <EmbellishedCardGrid
      items={formattedArticles}
      title={title}
      subtitle={subtitle}
    />
  )
}

RelatedArticleGrid.propTypes = {
  articles: array,
  title: string,
  subtitle: string
}

export default RelatedArticleGrid
