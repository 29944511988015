import React from 'react'
import { bool, node } from 'prop-types'
import classNames from 'classnames'

import styles from './SpacingReverser.module.scss'

const SpacingReverser = ({ children, panel, main }) => (
  <div
    className={classNames(
      styles.SpacingReverser,
      main && styles.main,
      panel && styles.panel
    )}
  >
    {children}
  </div>
)

SpacingReverser.propTypes = {
  children: node.isRequired,
  main: bool,
  panel: bool
}

export default SpacingReverser
