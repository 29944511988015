import React from 'react'
import { node } from 'prop-types'

import styles from './Corners.module.scss'

const Corners = ({ children }) => (
  <div className={styles.Corners}>{children}</div>
)

Corners.propTypes = {
  children: node.isRequired
}

export default Corners
