import React from 'react'
import { func, shape, arrayOf, string } from 'prop-types'

import FilterPanel from '@/component/Primitive/FilterPanel'
import SpacingReverser from '@/component/Primitive/SpacingReverser'

const RecommendationWizardWidget = ({
  __url,
  title,
  description,
  ctaText,
  skillOptions,
  ageOptions,
  titleColor,
  copyColor,
  articleLimit,
  strategyLimit
}) => {
  return (
    <SpacingReverser panel>
      <FilterPanel
        action={__url('/recommendation')}
        title={title}
        description={description}
        supportOptionsTitle="My child needs support with:"
        supportOptions={skillOptions}
        ageGroupsTitle="My child’s age is:"
        ageGroups={ageOptions}
        ctaText={ctaText}
        titleColor={titleColor}
        copyColor={copyColor}
        limits={{
          articleLimit: articleLimit || null,
          strategyLimit: strategyLimit || null
        }}
      />
    </SpacingReverser>
  )
}

const optionShape = shape({
  label: string.isRequired,
  value: string.isRequired
})

RecommendationWizardWidget.propTypes = {
  __url: func.isRequired,
  title: string.isRequired,
  description: string,
  ctaText: string.isRequired,
  skillOptions: arrayOf(optionShape).isRequired,
  ageOptions: arrayOf(optionShape).isRequired,
  titleColor: string,
  copyColor: string,
  articleLimit: string,
  strategyLimit: string
}

export default RecommendationWizardWidget
