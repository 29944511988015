import imageProperties from '../lib/image-properties'
import binaryProperties from '../lib/binary-properties'

const createQuery = () => `
  ... on AssetGridWidget {
    assets {
      title
      description
      images {
        cover_16x9_310 {
          ${imageProperties}
        }
      }
      assets {
        file {
          ${binaryProperties}
        }
      }
    }
  }
`
export default createQuery
