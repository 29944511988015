import React, { useState } from 'react'
import { string, object, func } from 'prop-types'

import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Fieldset from '@/component/Primitive/Fieldset'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import FieldWrapper from '@/component/Primitive/Field/component/FieldWrapper'
import Stack from '@/component/Primitive/Stack'
import TextControl from '@/component/Primitive/TextControl'
import styles from './DyslexiaAwareBadge.module.scss'

const DyslexiaAwareBadge = ({ country, errors, register }) => {
  const [teachersData, setTeachersData] = useState([{}])

  const handleAddRow = () => {
    const copy = Array.from(teachersData)
    copy.push({})
    setTeachersData(copy)
  }

  const getTeacherErrorName = (index) =>
    errors &&
    errors.teachers &&
    errors.teachers[index] &&
    errors.teachers[index].name &&
    errors.teachers[index].name.message

  const getTeacherErrorEmail = (index) =>
    errors &&
    errors.teachers &&
    errors.teachers[index] &&
    errors.teachers[index].email &&
    errors.teachers[index].email.message

  const getTeacherErrorPosition = (index) =>
    errors &&
    errors.teachers &&
    errors.teachers[index] &&
    errors.teachers[index].position &&
    errors.teachers[index].position.message

  return (
    <>
      <Fieldset row>
        <FieldTemplate
          label="First Name"
          controlName="firstName"
          status={errors.firstName ? 'error' : undefined}
          feedback={errors.firstName && errors.firstName.message}
        >
          <TextControl {...register('firstName')} />
        </FieldTemplate>

        <FieldTemplate
          label="Last Name"
          controlName="lastName"
          status={errors.lastName ? 'error' : undefined}
          feedback={errors.lastName && errors.lastName.message}
        >
          <TextControl {...register('lastName')} />
        </FieldTemplate>
      </Fieldset>

      <FieldTemplate
        label="Admin email (This is the email you used to sign up for Nessy)"
        controlName="adminEmail"
        status={errors.adminEmail ? 'error' : undefined}
        feedback={errors.adminEmail && errors.adminEmail.message}
      >
        <TextControl {...register('adminEmail')} />
      </FieldTemplate>

      <Stack gap="large">
        <FieldTemplate
          label="Secret Word"
          controlName="nessySecretWord"
          status={errors.nessySecretWord ? 'error' : undefined}
          feedback={errors.nessySecretWord && errors.nessySecretWord.message}
        >
          <TextControl {...register('nessySecretWord')} />
        </FieldTemplate>

        <FieldTemplate
          label="School Name"
          controlName="schoolName"
          status={errors.schoolName ? 'error' : undefined}
          feedback={errors.schoolName && errors.schoolName.message}
        >
          <TextControl {...register('schoolName')} />
        </FieldTemplate>
      </Stack>

      <FieldTemplate
        label="School Address"
        controlName="schoolAddress"
        status={errors.schoolAddress ? 'error' : undefined}
        feedback={errors.schoolAddress && errors.schoolAddress.message}
      >
        <TextControl {...register('schoolAddress')} />
      </FieldTemplate>

      <FieldTemplate
        label="Country"
        controlName="country"
        status={errors.country ? 'error' : undefined}
        feedback={errors.country && errors.country.message}
      >
        <TextControl {...register('country')} />
      </FieldTemplate>

      <Stack gap="large">
        <FieldTemplate
          label={country === 'UK' ? 'School Postcode' : 'School Zipcode'}
          controlName="postcode"
          status={errors.postcode ? 'error' : undefined}
          feedback={errors.postcode && errors.postcode.message}
        >
          <TextControl {...register('postcode')} />
        </FieldTemplate>

        <FieldTemplate
          label="No. of pupils in Primary Education"
          controlName="noOfPupils"
          status={errors.noOfPupils ? 'error' : undefined}
          feedback={errors.noOfPupils && errors.noOfPupils.message}
        >
          <TextControl {...register('noOfPupils')} />
        </FieldTemplate>
      </Stack>

      <Stack gap="large">
        <FieldTemplate
          label="No. of staff in Primary Education (includes teachers, TA's and SENCO's)"
          controlName="noOfStaff"
          status={errors.noOfStaff ? 'error' : undefined}
          feedback={errors.noOfStaff && errors.noOfStaff.message}
        >
          <TextControl {...register('noOfStaff')} />
        </FieldTemplate>

        <table className={styles.Table}>
          <caption>The following teachers have completed the course:</caption>
          <thead>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Title/Position</th>
          </thead>
          <tbody>
            {teachersData.map((teacher, index) => {
              return (
                <tr key={index}>
                  <td data-label="Name">
                    <FieldWrapper
                      controlName={`teachers.${index}.name`}
                      status={
                        errors &&
                        errors.teachers &&
                        errors.teachers[index] &&
                        errors.teachers[index].name
                          ? 'error'
                          : undefined
                      }
                      feedback={getTeacherErrorName(index)}
                    >
                      <TextControl {...register(`teachers.${index}.name`)} />
                    </FieldWrapper>
                  </td>
                  <td data-label="Email">
                    <FieldWrapper
                      controlName={`teachers.${index}.email`}
                      status={
                        errors &&
                        errors.teachers &&
                        errors.teachers[index] &&
                        errors.teachers[index].email
                          ? 'error'
                          : undefined
                      }
                      feedback={getTeacherErrorEmail(index)}
                    >
                      <TextControl {...register(`teachers.${index}.email`)} />
                    </FieldWrapper>
                  </td>
                  <td data-label="Title/Position">
                    <FieldWrapper
                      controlName={`teachers.${index}.position`}
                      status={
                        errors &&
                        errors.teachers &&
                        errors.teachers[index] &&
                        errors.teachers[index].position
                          ? 'error'
                          : undefined
                      }
                      feedback={getTeacherErrorPosition(index)}
                    >
                      <TextControl
                        {...register(`teachers.${index}.position`)}
                      />
                    </FieldWrapper>
                  </td>
                </tr>
              )
            })}
          </tbody>
          <ButtonStandard block onClick={handleAddRow}>
            + Add more
          </ButtonStandard>
        </table>
      </Stack>

      <FieldTemplate
        label="Comments"
        controlName="comments"
        status={errors.comments ? 'error' : undefined}
        feedback={errors.comments && errors.comments.message}
      >
        <TextControl multiLine {...register('comments')} />
      </FieldTemplate>
    </>
  )
}

DyslexiaAwareBadge.propTypes = {
  country: string.isRequired,
  errors: object.isRequired,
  register: func.isRequired
}

export default DyslexiaAwareBadge
