import React from 'react'
import { node } from 'prop-types'

import styles from './CheckControlText.module.scss'

const CheckControlText = ({ children }) => {
  return <p className={styles.CheckControlText}>{children}</p>
}

CheckControlText.propTypes = {
  children: node.isRequired
}

export default CheckControlText
