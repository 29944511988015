import React, { useEffect, useState } from 'react'
import { array, node, string, bool } from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './VideoPreview.module.scss'

import BackgroundImage from '../BackgroundImage'
import Icon from '../Icon'
import ResponsiveMedia from '../ResponsiveMedia'
import Stack from '../Stack'
import Type from '../Type'

const VideoPreview = ({
  attributionPrimary,
  attributionSecondary,
  alternateLayout,
  children,
  description,
  srcSet,
  alt,
  title,
  titleColor,
  copyColor
}) => {
  const [active, toggleActive] = useState(false)
  const [mounted, setMounted] = useState(false)
  const { t } = useTranslation()

  const attribution = attributionPrimary || attributionSecondary

  const videoDetails = (
    <div
      className={classNames(
        styles.VideoPreviewOverlayContent,
        styles.VideoPreviewOverlayDetails
      )}
    >
      <Stack gap={alternateLayout ? 'small' : 'medium'}>
        {title && (
          <Type
            as="h3"
            size="heading5"
            color={titleColor === '#ffffff' ? 'white' : titleColor}
          >
            {title}
          </Type>
        )}
        {description && (
          <div
            className={alternateLayout && styles.alternateDescription}
            style={{ color: copyColor }}
          >
            {description}
          </div>
        )}
      </Stack>
    </div>
  )

  const videoAttribution = (
    <div className={styles.VideoPreviewOverlayContent}>
      {attributionPrimary && (
        <Type as="div" size="heading5" color={copyColor}>
          {attributionPrimary}
        </Type>
      )}
      {attributionSecondary && (
        <div style={{ color: copyColor }}>{attributionSecondary}</div>
      )}
    </div>
  )

  const videoPreviewOverlay = (
    <div className={styles.VideoPreviewOverlay}>
      {videoDetails}
      {attribution && videoAttribution}
    </div>
  )

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <div
      className={classNames(
        styles.VideoPreview,
        active && styles.active,
        alternateLayout && styles.alternateLayout
      )}
    >
      {alternateLayout && videoDetails}
      <div className={styles.VideoPreviewPlaceholder}>
        <ResponsiveMedia ratio={9 / 16}>
          {mounted && (
            <div className={styles.VideoPreviewContent}>{children}</div>
          )}
          <div className={styles.VideoPreviewBg}>
            <BackgroundImage alt={alt} fillContainer srcSet={srcSet} />
          </div>
          <button
            className={styles.VideoPreviewAction}
            onClick={() => toggleActive(true)}
          >
            <div className={styles.VideoPreviewActionInner}>
              <Icon type="play" a11yText={t('VideoPlay')} width={56} />
            </div>
          </button>
        </ResponsiveMedia>
      </div>
      {alternateLayout ? videoAttribution : videoPreviewOverlay}
    </div>
  )
}

VideoPreview.propTypes = {
  attributionPrimary: string,
  attributionSecondary: string,
  children: node.isRequired,
  description: string,
  alt: string.isRequired,
  srcSet: array.isRequired,
  title: string,
  alternateLayout: bool,
  titleColor: string,
  copyColor: string
}

export default VideoPreview
