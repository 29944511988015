import React from 'react'
import { array, string } from 'prop-types'

import EmbellishedCardGrid from '@/component/Composition/EmbellishedCardGrid'

const AssetGrid = ({ title, titleColor, copyColor, assets }) => {
  const formattedAssets = assets?.map((asset) => ({
    title: asset.title,
    sell: asset.description,
    link: asset.assets?.file?.[0]?.download,
    src: asset.images?.cover_16x9_310?.[0]?.url
  }))

  return (
    <EmbellishedCardGrid
      items={formattedAssets}
      title={title}
      titleColor={titleColor}
      copyColor={copyColor}
    />
  )
}

AssetGrid.propTypes = {
  assets: array,
  title: string,
  titleColor: string,
  copyColor: string
}

export default AssetGrid
