import React from 'react'
import { node } from 'prop-types'

import styles from './CardGrid.module.scss'

const CardGrid = ({ children }) => (
  <div className={styles.CardGrid}>
    <ul className={styles.CardGridList}>{children}</ul>
  </div>
)

CardGrid.propTypes = {
  children: node.isRequired
}

export default CardGrid
