import React, { useContext } from 'react'
import { string, object } from 'prop-types'

import { RowContext } from '@/component/Context/RowContext'

import Container from '@/component/Primitive/Container'
import VisuallyHidden from '@/component/Primitive/VisuallyHidden'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
import SmartLink from '@/component/Primitive/SmartLink'
import Hide from '@/component/Primitive/Hide'
import Corners from '@/component/Primitive/Corners'

const BannerImageWidget = ({ ctaLink, title, images }) => {
  const contextRowType = useContext(RowContext)
  const getImage = (context, ratio, size) =>
    images?.[`${context}_${ratio}_${size}`]?.[0]
  const largeSrcSet = [
    {
      width: 600,
      src: getImage('large', '5x1', 600)?.url
    },
    {
      width: 800,
      src: getImage('large', '5x1', 800)?.url
    },
    {
      width: 1048,
      src: getImage('large', '5x1', 1048)?.url
    }
  ]
  const smallSrcSet = [
    {
      width: 400,
      src: getImage('small', '16x9', 400)?.url
    },
    {
      width: 600,
      src: getImage('small', '16x9', 600)?.url
    }
  ]

  const sizes = [
    '(min-width: 1176px) 1048px',
    '(min-width: 946px) calc(100vw - 128px)',
    'calc(100vw - 64px)'
  ]

  return (
    <Container center size="large" gutter={contextRowType === 'panelled'}>
      <Corners>
        <SmartLink href={ctaLink}>
          <VisuallyHidden>{title}</VisuallyHidden>
          <Hide below="tablet">
            <ResponsiveImage
              alt=""
              src={largeSrcSet[0].src}
              srcSet={largeSrcSet}
              sizes={sizes}
              width={1000}
              height={202}
            />
          </Hide>
          <Hide at="tablet">
            <ResponsiveImage
              alt=""
              src={smallSrcSet[0].src}
              srcSet={smallSrcSet}
              sizes={sizes}
              width={480}
              height={270}
            />
          </Hide>
        </SmartLink>
      </Corners>
    </Container>
  )
}

BannerImageWidget.propTypes = {
  title: string.isRequired,
  ctaLink: string.isRequired,
  images: object.isRequired
}

export default BannerImageWidget
