import imageProperties from '../lib/image-properties'

const createQuery = () => `
  ... on ArticleGridWidget {
    dedupe
    limit
    title
    ctaText
    ctaLink
    subtitle
    inlineMobile
    lists
    articles {
      _id
      headline
      shortHeadline
      fullUrlPath
      sell
      images {
        thumbnail_16x9_310 {
          ${imageProperties}
        }
      }
    }
  }
`
export default createQuery
