import React from 'react'
import PropTypes from 'prop-types'

import styles from './ProductPromo.module.scss'

import Tile from '@/component/Primitive/Tile'
import Type from '@/component/Primitive/Type'
import Stack from '@/component/Primitive/Stack'
import Inline from '@/component/Primitive/Inline'
import SmartLink from '@/component/Primitive/SmartLink'
import ButtonOutline from '@/component/Primitive/ButtonOutline'
import ButtonStandard from '@/component/Primitive/ButtonStandard'

const ProductPromo = ({
  link,
  name,
  image,
  srcSet,
  details,
  primaryCtaText,
  primaryCtaLink,
  secondaryCtaText,
  secondaryCtaLink,
  titleColor,
  copyColor
}) => (
  <li className={styles.ProductPromo}>
    <Stack>
      <Inline fullWidth middle noWrap gap="product-promo">
        <Tile src={image} srcSet={srcSet} alt={name} />
        <div className={styles.ProductPromoContent}>
          <Type as="h3" size="heading2" color={titleColor || 'purple'}>
            <SmartLink href={link}>{name}</SmartLink>
          </Type>
          <Type color={copyColor}>{details}</Type>
          <Type size="heading2">
            <div className={styles.ProductPromoActions}>
              {primaryCtaLink && primaryCtaText && (
                <ButtonOutline accent href={primaryCtaLink}>
                  {primaryCtaText}
                </ButtonOutline>
              )}
              {secondaryCtaLink && secondaryCtaText && (
                <ButtonStandard accent href={secondaryCtaLink}>
                  {secondaryCtaText}
                </ButtonStandard>
              )}
            </div>
          </Type>
        </div>
      </Inline>
    </Stack>
  </li>
)

ProductPromo.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  srcSet: PropTypes.array,
  details: PropTypes.string,
  primaryCtaText: PropTypes.string,
  primaryCtaLink: PropTypes.string,
  secondaryCtaText: PropTypes.string,
  secondaryCtaLink: PropTypes.string,
  titleColor: PropTypes.string,
  copyColor: PropTypes.string
}

export default ProductPromo
