import React from 'react'
import { object, func } from 'prop-types'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import TextControl from '@/component/Primitive/TextControl'
import CheckControl from '@/component/Primitive/CheckControl'
import SelectControl from '@/component/Primitive/SelectControl'
import Fieldset from '@/component/Primitive/Fieldset'

const HelpAndSupport = ({ errors, watch, register }) => {
  const accountType = watch('accountType')
  const existingCustomer = watch('existingCustomer')
  const program = watch('program')
  return (
    <>
      <Fieldset row>
        <FieldTemplate
          label="First Name"
          controlName="firstName"
          status={errors.firstName ? 'error' : undefined}
          feedback={errors.firstName && errors.firstName.message}
        >
          <TextControl {...register('firstName')} />
        </FieldTemplate>

        <FieldTemplate
          label="Last Name"
          controlName="lastName"
          status={errors.lastName ? 'error' : undefined}
          feedback={errors.lastName && errors.lastName.message}
        >
          <TextControl {...register('lastName')} />
        </FieldTemplate>
      </Fieldset>

      <FieldTemplate
        label="Email"
        controlName="email"
        status={errors.email ? 'error' : undefined}
        feedback={errors.email && errors.email.message}
      >
        <TextControl {...register('email')} />
      </FieldTemplate>

      <FieldTemplate
        label="Account Type"
        controlName="accountType"
        status={errors.accountType ? 'error' : undefined}
        feedback={errors.accountType && errors.accountType.message}
      >
        <SelectControl {...register('accountType')}>
          <option value="" selected disabled>
            -- Select an account type --
          </option>
          <option>School</option>
          <option>Home</option>
          <option>Other</option>
        </SelectControl>
      </FieldTemplate>

      {accountType === 'School' && (
        <>
          <FieldTemplate
            label="School Name"
            controlName="schoolName"
            status={errors.schoolName ? 'error' : undefined}
            feedback={errors.schoolName && errors.schoolName.message}
          >
            <TextControl {...register('schoolName')} />
          </FieldTemplate>

          <FieldTemplate
            label="Postcode/Zip Code"
            controlName="postcode"
            status={errors.postcode ? 'error' : undefined}
            feedback={errors.postcode && errors.postcode.message}
          >
            <TextControl {...register('postcode')} />
          </FieldTemplate>
        </>
      )}

      {['Home', 'Other'].includes(accountType) && (
        <FieldTemplate
          label="Country"
          controlName="country"
          status={errors.country ? 'error' : undefined}
          feedback={errors.country && errors.country.message}
        >
          <TextControl {...register('country')} />
        </FieldTemplate>
      )}

      <FieldTemplate
        controlName="existingCustomer"
        status={errors.existingCustomer ? 'error' : undefined}
        feedback={errors.existingCustomer && errors.existingCustomer.message}
      >
        <CheckControl type="checkbox" {...register('existingCustomer')}>
          Are you an existing customer?
        </CheckControl>
      </FieldTemplate>

      {existingCustomer && (
        <FieldTemplate
          label="Nessy Secret Word"
          controlName="nessySecretWord"
          status={errors.nessySecretWord ? 'error' : undefined}
          feedback={errors.nessySecretWord && errors.nessySecretWord.message}
        >
          <TextControl {...register('nessySecretWord')} />
        </FieldTemplate>
      )}

      <FieldTemplate
        label="Which Nessy programs are you having a problem with?"
        controlName="program"
        status={errors.program ? 'error' : undefined}
        feedback={errors.program && errors.program.message}
      >
        <SelectControl {...register('program')}>
          <option value="" selected disabled>
            -- Select a program --
          </option>
          <option>Nessy Reading and Spelling</option>
          <option>Hairy Reading</option>
          <option>Nessy Numbersense</option>
          <option>Writing Beach</option>
          <option>Dyslexia Quest screener</option>
          <option>Nessy Fingers touch typing</option>
          <option>Understanding Dyslexia training course</option>
          <option>Apple, Amazon and Play Store apps</option>
          <option>Books, bags and card games</option>
          <option value="Other">
            Other – Please tell us which program you are using in the box below
          </option>
        </SelectControl>
      </FieldTemplate>

      {program === 'Other' && (
        <FieldTemplate
          label="Program"
          controlName="otherProgram"
          status={errors.otherProgram ? 'error' : undefined}
          feedback={errors.otherProgram && errors.otherProgram.message}
        >
          <TextControl {...register('otherProgram')} />
        </FieldTemplate>
      )}

      <FieldTemplate
        label="Description of the issue are you having"
        controlName="problemDescription"
        status={errors.problemDescription ? 'error' : undefined}
        feedback={
          errors.problemDescription && errors.problemDescription.message
        }
      >
        <TextControl multiLine {...register('problemDescription')} />
      </FieldTemplate>

      <FieldTemplate
        label="Please tell us the type of device you are using, the model and the operating system, so that we can best help you"
        controlName="device"
        status={errors.device ? 'error' : undefined}
        feedback={errors.device && errors.device.message}
      >
        <TextControl {...register('device')} />
      </FieldTemplate>

      <FieldTemplate
        label="Nessy works best with Chrome. Which Internet browser are you using?"
        controlName="browser"
        status={errors.browser ? 'error' : undefined}
        feedback={errors.browser && errors.browser.message}
      >
        <TextControl {...register('browser')} />
      </FieldTemplate>
    </>
  )
}

HelpAndSupport.propTypes = {
  errors: object.isRequired,
  watch: func.isRequired,
  register: func.isRequired
}

export default HelpAndSupport
