import React from 'react'
import { node, string } from 'prop-types'

const RowContext = React.createContext()

const RowContextProvider = ({ children, type }) => (
  <RowContext.Provider value={type}>{children}</RowContext.Provider>
)

RowContextProvider.propTypes = {
  children: node.isRequired,
  type: string
}

const RowContextConsumer = RowContext.Consumer

export { RowContext, RowContextProvider, RowContextConsumer }
