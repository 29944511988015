import React, { useEffect, useState } from 'react'
import { arrayOf, bool, node, oneOf } from 'prop-types'
import classNames from 'classnames'

import styles from './WidgetWrapper.module.scss'

const possibleDisplayOptions = ['desktop', 'tablet', 'mobile']

const WidgetWrapper = ({ children, displayOptions, essential }) => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])

  const hiddenBreakpoints = possibleDisplayOptions.filter(
    (option) => !displayOptions.includes(option)
  )

  return (
    <div
      className={classNames(
        styles.WidgetWrapper,
        hiddenBreakpoints.map((breakpoint) => styles[`hidden-${breakpoint}`])
      )}
    >
      {(essential || (!essential && mounted)) && children}
    </div>
  )
}

WidgetWrapper.defaultProps = {
  essential: true,
  displayOptions: []
}

WidgetWrapper.propTypes = {
  children: node.isRequired,
  essential: bool,
  displayOptions: arrayOf(oneOf(possibleDisplayOptions)).isRequired
}

export default WidgetWrapper
