import { createValidator, booleanToCallback } from '@clocklimited/validity'
import schemata from 'schemata'
import required from '@clocklimited/validity-required'
import isEmail from '@clocklimited/validity-email'
import ifSet from '@clocklimited/validity-validate-if-set'

const termsValidator = createValidator(
  booleanToCallback((value) => value === true)
)('You must agree to the Terms and Conditions & Privacy Policy')

const atLeastOneSelected = createValidator(
  booleanToCallback((value) => value && value.length >= 1)
)('You must select at least one option')

const createSchema = (questions) => {
  if (!questions) return schemata({ name: 'FormBuilderFormSchema' })
  const questionSchema = questions.reduce(
    (schema, question, i) => {
      schema[`question-${i}`] = {}
      schema[`question-${i}`].validators = []
      if (question.required) {
        schema[`question-${i}`].validators.push(
          required.setFailureMessage('This is required')
        )
      }
      if (question.inputType === 'checkbox' && question.required) {
        schema[`question-${i}`].validators.push(atLeastOneSelected)
      }
      if (question.inputType === 'email') {
        schema[`question-${i}`].validators.push(
          ifSet(isEmail.setFailureMessage('Must be a valid email'))
        )
      }
      return schema
    },
    {
      termsAgreed: {
        type: Boolean,
        validators: [required, termsValidator]
      }
    }
  )
  return schemata({
    name: 'FormBuilderFormSchema',
    properties: questionSchema
  })
}

export default createSchema
