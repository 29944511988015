import React, { useContext } from 'react'
import { array, bool, node, number, string } from 'prop-types'
import classNames from 'classnames'

import hexToRgb from './lib/hex-to-rgb-converter'
import minifyCssString from '@/lib/css-string-minifier'
import { AccentColorContext } from '@/component/Context/AccentColorContext'

import styles from './Hero.module.scss'

import BackgroundImage from '../BackgroundImage'

const Hero = ({
  cappedHeight,
  children,
  color,
  ratio,
  srcSet,
  videoSrc,
  overlap
}) => {
  const contextAccentColor = useContext(AccentColorContext)
  const derivedColor = color || contextAccentColor
  const rgb = derivedColor && hexToRgb(derivedColor).join(',')

  // "eased" gradient for smoother solid -> transparent transition
  const gradient = minifyCssString(`
      rgba(${rgb}, 1) 0%,
      rgba(${rgb}, 0.987) 9.5%,
      rgba(${rgb}, 0.951) 17.9%,
      rgba(${rgb}, 0.896) 25.2%,
      rgba(${rgb}, 0.825) 31.7%,
      rgba(${rgb}, 0.741) 37.5%,
      rgba(${rgb}, 0.648) 42.8%,
      rgba(${rgb}, 0.55) 47.9%,
      rgba(${rgb}, 0.45) 52.9%,
      rgba(${rgb}, 0.352) 57.9%,
      rgba(${rgb}, 0.259) 63.2%,
      rgba(${rgb}, 0.175) 68.9%,
      rgba(${rgb}, 0.104) 75.3%,
      rgba(${rgb}, 0.049) 82.5%,
      rgba(${rgb}, 0.013) 90.6%,
      rgba(${rgb}, 0) 100%
  `)

  const formattedRatio = ratio && parseFloat((ratio * 100).toFixed(5))

  return (
    <div
      className={classNames(
        styles.Hero,
        !ratio && styles.manualHeight,
        overlap && styles.HeroOverlap
      )}
      style={{ backgroundColor: `${derivedColor}` }}
    >
      <div className={styles.HeroBackground}>
        <BackgroundImage
          alt=""
          ratio={ratio || null}
          fillContainer
          srcSet={srcSet}
          size="cover"
        />
        {rgb && (
          <div
            className={styles.HeroBackgroundFade}
            style={{
              backgroundImage: `linear-gradient(to top, ${gradient}), linear-gradient(to bottom, ${gradient})`
            }}
          />
        )}
      </div>
      {videoSrc && (
        <div className={styles.HeroVideo}>
          <div className={styles.HeroVideoInner}>
            <video src={videoSrc} autoPlay muted loop playsInline />
            {rgb && (
              <div
                className={styles.HeroBackgroundFade}
                style={{
                  backgroundImage: `linear-gradient(to top, ${gradient}), linear-gradient(to bottom, ${gradient})`
                }}
              />
            )}
          </div>
        </div>
      )}
      <div
        className={styles.HeroContent}
        {...(ratio &&
          !cappedHeight && {
            style: { minHeight: `${formattedRatio}vw` }
          })}
        {...(cappedHeight && {
          style: { minHeight: `min(${formattedRatio}vw, 75vh)` }
        })}
      >
        <div className={styles.HeroContentInner}>{children}</div>
      </div>
    </div>
  )
}

Hero.propTypes = {
  cappedHeight: bool,
  overlap: bool,
  children: node,
  color: string,
  ratio: number,
  srcSet: array.isRequired,
  videoSrc: string
}

export default Hero
