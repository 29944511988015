import React from 'react'
import { arrayOf, node, number, shape, string, bool } from 'prop-types'

import CaptionedMedia from '@/component/Primitive/CaptionedMedia'
import ImageFrame from '@/component/Primitive/ImageFrame'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'

const InlineImage = ({
  alt,
  caption,
  carousel,
  frameRatio,
  height,
  ratio,
  safeAreaRight,
  sizes,
  src,
  srcSet,
  width,
  link
}) => {
  return (
    <CaptionedMedia caption={caption} safeAreaRight={safeAreaRight}>
      {frameRatio === 9 / 16 || carousel ? (
        <ResponsiveMedia ratio={frameRatio || height / width}>
          <ImageFrame>
            <ResponsiveImage
              width={width}
              height={height}
              alt={alt}
              src={src}
              srcSet={srcSet}
              sizes={sizes}
            />
          </ImageFrame>
        </ResponsiveMedia>
      ) : (
        <ImageFrame noBackground>
          <ResponsiveImage
            width={width}
            height={height}
            alt={alt}
            src={src}
            srcSet={srcSet}
            sizes={sizes}
            link={link}
          />
        </ImageFrame>
      )}
    </CaptionedMedia>
  )
}

InlineImage.propTypes = {
  alt: string,
  caption: node,
  carousel: bool,
  frameRatio: number,
  height: number,
  ratio: number,
  safeAreaRight: string,
  sizes: arrayOf(string),
  src: string,
  srcSet: arrayOf(shape({ width: number.isRequired, src: string.isRequired })),
  width: number,
  link: string
}

export default InlineImage
