import React from 'react'
import { bool, array } from 'prop-types'

import Container from '@/component/Primitive/Container'
import Product from '@/component/Primitive/Product'
import ProductGrid from '@/component/Primitive/ProductGrid'

import generateLink from '../ProductPromo/lib/link-generator'

const ProductGridWidget = ({
  products,
  showProductDescriptions,
  showProductPricingDetails,
  showProductTitlesAndVersionTypes
}) => {
  return (
    <Container center gutter size="large">
      <ProductGrid>
        {products &&
          products.length > 0 &&
          products.map((product, i) => {
            const link = generateLink(product)
            const imageUrl = product?.images?.thumbnail_1x1_300?.[0]?.url

            return (
              <Product
                key={`id-${i}`}
                image={imageUrl}
                link={link}
                title={product.name}
                widgetOptions={{
                  showProductDescriptions,
                  showProductPricingDetails,
                  showProductTitlesAndVersionTypes
                }}
                {...product}
              />
            )
          })}
      </ProductGrid>
    </Container>
  )
}

ProductGridWidget.propTypes = {
  products: array,
  showProductDescriptions: bool,
  showProductPricingDetails: bool,
  showProductTitlesAndVersionTypes: bool
}

export default ProductGridWidget
