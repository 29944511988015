import React from 'react'
import { bool, string } from 'prop-types'
import classNames from 'classnames'

import styles from './ButtonOutline.module.scss'

import ButtonBase from '../ButtonBase'

const ButtonOutline = ({ className, disabled, inverse, accent, ...other }) => (
  <ButtonBase
    className={classNames(
      styles.ButtonOutline,
      disabled && styles.disabled,
      inverse && styles.inverse,
      accent && styles.accent,
      className
    )}
    disabled={disabled}
    {...other}
  />
)

ButtonOutline.propTypes = {
  className: string,
  disabled: bool,
  inverse: bool,
  accent: bool
}

export default ButtonOutline
