import imageProperties from '../lib/image-properties'

const createQuery = () => `
  ... on InlineImageWidget {
    inlineImageImages: images {
      crop
      images {
        inline_16x9_1664 {
          ${imageProperties}
        }
        inline_16x9_832 {
          ${imageProperties}
        }
        inline_16x9_600 {
          ${imageProperties}
        }
        inline_16x9_400 {
          ${imageProperties}
        }
        inline_1x1_936 {
          ${imageProperties}
        }
        inline_1x1_468 {
          ${imageProperties}
        }
        inline_1x1_400 {
          ${imageProperties}
        }
        inline_free_1664 {
          ${imageProperties}
        }
        inline_free_832 {
          ${imageProperties}
        }
        inline_free_500 {
          ${imageProperties}
        }
      }
    }
  }
`
export default createQuery
