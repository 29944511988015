import React from 'react'
import { node, bool } from 'prop-types'

import styles from './ImageFrame.module.scss'
import classNames from 'classnames'

const ImageFrame = ({ children, noBackground }) => (
  <div
    className={classNames(
      styles.ImageFrame,
      noBackground && styles.noBackground
    )}
  >
    {children}
  </div>
)

ImageFrame.propTypes = {
  children: node.isRequired,
  noBackground: bool
}

export default ImageFrame
