const widgets = {
  articleGrid: require('./ArticleGrid').default,
  articleLayoutBody: require('./ArticleLayoutBody').default,
  articleLayoutHeader: require('./ArticleLayoutHeader').default,
  assetGrid: require('./AssetGrid').default,
  banner: require('./Banner').default,
  bannerImage: require('./BannerImage').default,
  cta: require('./Cta').default,
  faq: require('./Faq').default,
  formBuilder: require('./FormBuilder').default,
  header: require('./Header').default,
  hero: require('./Hero').default,
  html: require('./Html').default,
  imageWithText: require('./ImageWithText').default,
  inlineImage: require('./InlineImage').default,
  inlineVideo: require('./InlineVideo').default,
  productContent: require('./ProductContent').default,
  productGrid: require('./ProductGrid').default,
  productHero: require('./ProductHero').default,
  productPromo: require('./ProductPromo').default,
  contactForm: require('./ContactForm').default,
  promo: require('./Promo').default,
  promoDouble: require('./PromoDouble').default,
  promoDoubleSquares: require('./PromoDoubleSquares').default,
  relatedWidgets: require('./RelatedWidgets').default,
  relatedContent: require('./RelatedContent').default,
  recommendationWizard: require('./RecommendationWizard').default,
  share: require('./Share').default,
  superHero: require('./SuperHero').default,
  text: require('./Text').default,
  textHero: require('./TextHero').default,
  relatedArticles: require('./RelatedArticles').default,
  video: require('./Video').default,
  videoSwitcher: require('./VideoSwitcher').default,
  gettingStartedContent: require('./GettingStartedContent').default
}

module.exports = widgets
