import React, { useState } from 'react'
import { arrayOf, object, string } from 'prop-types'

import TileGrid from '../TileGrid'

import Container from '@/component/Primitive/Container'
import Stack from '@/component/Primitive/Stack'
import TextAlign from '@/component/Primitive/TextAlign'
import Tile from '@/component/Primitive/Tile'
import Type from '@/component/Primitive/Type'

const MediaSwitcher = ({ items, title }) => {
  const [activeIndex, updateActiveIndex] = useState(0)

  return (
    <Container center gutter size="large">
      <Stack gap="large">
        {title && (
          <TextAlign center>
            <Type as="h2" size="heading2" color="purple">
              {title}
            </Type>
          </TextAlign>
        )}
        <TileGrid>
          {items.map((item, i) => (
            <Tile
              key={`MediaSwitcher:Tile:${i}`}
              src={item.thumbnail}
              alt={item.alt || item.title || ''}
              onClick={() => updateActiveIndex(i)}
              active={i === activeIndex}
            />
          ))}
        </TileGrid>
        <div>{items[activeIndex].media}</div>
      </Stack>
    </Container>
  )
}

MediaSwitcher.defaultProps = {
  items: []
}

MediaSwitcher.propTypes = {
  items: arrayOf(object).isRequired, // TODO: shape
  title: string
}

export default MediaSwitcher
