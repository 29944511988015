import React from 'react'
import { bool, string } from 'prop-types'

import ActionRow from '../ActionRow'
import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Container from '@/component/Primitive/Container'
import PanelAlt from '@/component/Primitive/PanelAlt'
import Prose from '@/component/Primitive/Prose'
import Stack from '@/component/Primitive/Stack'
import TextAlign from '@/component/Primitive/TextAlign'
import Type from '@/component/Primitive/Type'

const Promo = ({
  title,
  text,
  ctaLink,
  ctaText,
  corners,
  titleColor,
  copyColor
}) => (
  <PanelAlt grow corners={corners}>
    <Container size="medium" gutter center>
      <Stack gap="large">
        <TextAlign center>
          <Stack>
            <Type as="h2" size="heading3" color={titleColor || 'white'}>
              {title}
            </Type>
            <Prose color={copyColor} dangerousHtml={text} />
          </Stack>
        </TextAlign>
        <ActionRow>
          <ButtonStandard href={ctaLink}>{ctaText}</ButtonStandard>
        </ActionRow>
      </Stack>
    </Container>
  </PanelAlt>
)

Promo.propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  ctaLink: string.isRequired,
  ctaText: string.isRequired,
  corners: bool,
  titleColor: string,
  copyColor: string
}

export default Promo
