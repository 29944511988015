import React, { useEffect, useState } from 'react'
import { object } from 'prop-types'

import Hero from '@/component/Primitive/Hero'
import SpacingReverser from '@/component/Primitive/SpacingReverser'
import useMedia from '@/hook/useMedia'
import changeFileExtension from '@/lib/filename-extension-modifier'

const HeroWidget = ({ images, videoAssets, videoAssetsMobile }) => {
  const desktopSrc = videoAssets.video?.[0]?.url
  const tabletSrc = videoAssetsMobile.video?.[0]?.url
  const [videoSrc, setVideoSrc] = useState(desktopSrc)
  const getImage = (size) => images?.[`hero_2x1_${size}`]?.[0]
  const isTablet = useMedia('(max-width: 600px)')

  useEffect(
    () => setVideoSrc(isTablet ? tabletSrc : desktopSrc),
    [isTablet, tabletSrc, desktopSrc]
  )

  const srcSet = [
    {
      width: 0,
      src: changeFileExtension(getImage(600)?.url, 'webp')
    },
    {
      width: 800,
      src: changeFileExtension(getImage(800)?.url, 'webp')
    },
    {
      width: 1000,
      src: changeFileExtension(getImage(1000)?.url, 'webp')
    },
    {
      width: 1280,
      src: changeFileExtension(getImage(1280)?.url, 'webp')
    },

    {
      width: 1600,
      src: changeFileExtension(getImage(1600)?.url, 'webp')
    },
    {
      width: 2000,
      src: changeFileExtension(getImage(2000)?.url, 'webp')
    },
    {
      width: 2500,
      src: changeFileExtension(getImage(2500)?.url, 'webp')
    }
  ]

  return (
    <SpacingReverser main>
      <Hero ratio={1 / 2} srcSet={srcSet} videoSrc={videoSrc} overlap />
    </SpacingReverser>
  )
}

HeroWidget.propTypes = {
  images: object,
  videoAssets: object,
  videoAssetsMobile: object
}

export default HeroWidget
