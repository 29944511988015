import React from 'react'
import { object, func } from 'prop-types'

import WidgetArea from '@/component/Structure/WidgetArea'

const RelatedWidgets = ({ article, section, pageData, __url }) => {
  const hasWidgets = article?.relatedWidgets?.widgets.length
  if (!hasWidgets) return null

  return (
    <WidgetArea
      widgets={article?.relatedWidgets?.widgets}
      article={article}
      section={section}
      pageData={pageData}
      __url={__url}
    />
  )
}

RelatedWidgets.propTypes = {
  section: object,
  article: object,
  pageData: object,
  __url: func.isRequired
}

export default RelatedWidgets
