import React from 'react'
import { string, object, func } from 'prop-types'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import TextControl from '@/component/Primitive/TextControl'
import CheckControl from '@/component/Primitive/CheckControl'
import SelectControl from '@/component/Primitive/SelectControl'
import Fieldset from '@/component/Primitive/Fieldset'
import Stack from '@/component/Primitive/Stack'

const HairyReadingFreeTrial = ({ country, errors, watch, register }) => {
  const existingCustomer = watch('existingCustomer')
  const isAccountAdministrator = watch('isAccountAdministrator')

  return (
    <>
      <Fieldset row>
        <FieldTemplate
          label="First Name"
          controlName="firstName"
          status={errors.firstName ? 'error' : undefined}
          feedback={errors.firstName && errors.firstName.message}
        >
          <TextControl {...register('firstName')} />
        </FieldTemplate>

        <FieldTemplate
          label="Last Name"
          controlName="lastName"
          status={errors.lastName ? 'error' : undefined}
          feedback={errors.lastName && errors.lastName.message}
        >
          <TextControl {...register('lastName')} />
        </FieldTemplate>
      </Fieldset>

      <FieldTemplate
        label="School Name"
        controlName="schoolName"
        status={errors.schoolName ? 'error' : undefined}
        feedback={errors.schoolName && errors.schoolName.message}
      >
        <TextControl {...register('schoolName')} />
      </FieldTemplate>

      <FieldTemplate
        label="Position"
        controlName="position"
        status={errors.position ? 'error' : undefined}
        feedback={errors.position && errors.position.message}
      >
        <TextControl {...register('position')} />
      </FieldTemplate>

      <FieldTemplate
        label="Phone Number"
        controlName="phoneNumber"
        status={errors.phoneNumber ? 'error' : undefined}
        feedback={errors.phoneNumber && errors.phoneNumber.message}
      >
        <TextControl {...register('phoneNumber')} />
      </FieldTemplate>

      <FieldTemplate
        label="Email (Note: Must be a school email address)"
        controlName="email"
        status={errors.email ? 'error' : undefined}
        feedback={errors.email && errors.email.message}
      >
        <TextControl {...register('email')} />
      </FieldTemplate>

      <FieldTemplate
        label="Will you be the account administrator?"
        controlName="isAccountAdministrator"
        status={errors.isAccountAdministrator ? 'error' : undefined}
        feedback={
          errors.isAccountAdministrator && errors.isAccountAdministrator.message
        }
      >
        <Stack gap="small">
          <CheckControl
            type="radio"
            value="true"
            {...register('isAccountAdministrator')}
          >
            Yes
          </CheckControl>
          <CheckControl
            type="radio"
            value="false"
            {...register('isAccountAdministrator')}
          >
            No
          </CheckControl>
        </Stack>
      </FieldTemplate>

      {isAccountAdministrator === 'false' && (
        <>
          <FieldTemplate
            label="Admin First Name"
            controlName="adminFirstName"
            status={errors.adminFirstName ? 'error' : undefined}
            feedback={errors.adminFirstName && errors.adminFirstName.message}
          >
            <TextControl {...register('adminFirstName')} />
          </FieldTemplate>
          <FieldTemplate
            label="Admin Last Name"
            controlName="adminLastName"
            status={errors.adminLastName ? 'error' : undefined}
            feedback={errors.adminLastName && errors.adminLastName.message}
          >
            <TextControl {...register('adminLastName')} />
          </FieldTemplate>
          <FieldTemplate
            label="Position/Title"
            controlName="adminPosition"
            status={errors.adminPosition ? 'error' : undefined}
            feedback={errors.adminPosition && errors.adminPosition.message}
          >
            <TextControl {...register('adminPosition')} />
          </FieldTemplate>
          <FieldTemplate
            label="Admin Email (Note: Must be a school email address)"
            controlName="adminEmail"
            status={errors.adminEmail ? 'error' : undefined}
            feedback={errors.adminEmail && errors.adminEmail.message}
          >
            <TextControl {...register('adminEmail')} />
          </FieldTemplate>
        </>
      )}

      <FieldTemplate
        controlName="existingCustomer"
        status={errors.existingCustomer ? 'error' : undefined}
        feedback={errors.existingCustomer && errors.existingCustomer.message}
      >
        <CheckControl type="checkbox" {...register('existingCustomer')}>
          Are you an existing customer?
        </CheckControl>
      </FieldTemplate>

      {existingCustomer && (
        <FieldTemplate
          label="Enter your Secret Word"
          controlName="nessySecretWord"
          status={errors.nessySecretWord ? 'error' : undefined}
          feedback={errors.nessySecretWord && errors.nessySecretWord.message}
        >
          <TextControl {...register('nessySecretWord')} />
        </FieldTemplate>
      )}

      <FieldTemplate
        label="Choose a Language Preference"
        controlName="preferedLanguage"
        status={errors.preferedLanguage ? 'error' : undefined}
        feedback={errors.preferedLanguage && errors.preferedLanguage.message}
      >
        <SelectControl {...register('preferedLanguage')}>
          <option value="" selected disabled>
            -- Select the program language --
          </option>
          {country === 'US' ? (
            <>
              <option>American English</option>
              <option>British English</option>
            </>
          ) : (
            <>
              <option>British English</option>
              <option>American English</option>
            </>
          )}
        </SelectControl>
      </FieldTemplate>

      <FieldTemplate
        label="Country"
        controlName="country"
        status={errors.country ? 'error' : undefined}
        feedback={errors.country && errors.country.message}
      >
        <TextControl {...register('country')} />
      </FieldTemplate>

      <FieldTemplate
        label="Postcode/Zip Code"
        controlName="postcode"
        status={errors.postcode ? 'error' : undefined}
        feedback={errors.postcode && errors.postcode.message}
      >
        <TextControl {...register('postcode')} />
      </FieldTemplate>

      {country === 'US' && (
        <>
          <FieldTemplate
            label="State"
            controlName="state"
            status={errors.state ? 'error' : undefined}
            feedback={errors.state && errors.state.message}
          >
            <TextControl {...register('state')} />
          </FieldTemplate>

          <FieldTemplate
            label="District"
            controlName="district"
            status={errors.district ? 'error' : undefined}
            feedback={errors.district && errors.district.message}
          >
            <TextControl {...register('district')} />
          </FieldTemplate>
        </>
      )}

      <FieldTemplate
        label="How many licenses would you like? (Note: 1 per student)"
        controlName="licenseQuantity"
        status={errors.licenseQuantity ? 'error' : undefined}
        feedback={errors.licenseQuantity && errors.licenseQuantity.message}
      >
        <TextControl {...register('licenseQuantity')} />
      </FieldTemplate>

      {country !== 'US' && (
        <FieldTemplate
          label="Comments"
          controlName="comments"
          status={errors.comments ? 'error' : undefined}
          feedback={errors.comments && errors.comments.message}
        >
          <TextControl multiLine {...register('comments')} />
        </FieldTemplate>
      )}
    </>
  )
}

HairyReadingFreeTrial.propTypes = {
  country: string.isRequired,
  errors: object.isRequired,
  watch: func.isRequired,
  register: func.isRequired
}

export default HairyReadingFreeTrial
