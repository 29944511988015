const schemata = require('schemata')
const required = require('@clocklimited/validity-required')

module.exports = () =>
  schemata({
    name: 'Teacher',
    properties: {
      name: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': [],
          'Dyslexia Aware Badge': [required]
        }
      },
      email: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': [],
          'Dyslexia Aware Badge': [required]
        }
      },
      position: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': [],
          'Dyslexia Aware Badge': [required]
        }
      }
    }
  })
