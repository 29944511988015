import React from 'react'
import { string } from 'prop-types'

const HtmlWidget = ({ html }) => (
  <div dangerouslySetInnerHTML={{ __html: html }} />
)

HtmlWidget.propTypes = {
  html: string
}

export default HtmlWidget
