import React from 'react'
import { bool, number, shape, string, arrayOf } from 'prop-types'
import classNames from 'classnames'

import Corners from '../Corners'
import ResponsiveMedia from '../ResponsiveMedia'
import ResponsiveImage from '../ResponsiveImage'
import Stack from '../Stack'
import Type from '../Type'
import Prose from '../Prose'
import SmartLink from '../SmartLink'

import styles from './SplitSummary.module.scss'

const SplitSummary = ({
  title,
  description,
  image,
  reverse,
  url,
  titleColor,
  copyColor
}) => {
  const { width, height, alt, src, srcSet } = image
  const El = url ? SmartLink : 'div'

  return (
    <El
      to={url}
      className={classNames(
        styles.SplitSummary,
        reverse && styles.reverse,
        url && styles.clickable
      )}
    >
      <div className={styles.SplitSummaryImage}>
        <Corners block>
          <ResponsiveMedia ratio={height / width}>
            <ResponsiveImage
              width={width}
              height={height}
              src={src}
              srcSet={srcSet}
              sizes={[
                '(min-width: 1144px) 546px',
                '(min-width: 800px) calc((100% - 64px) * 0.6 -48px)',
                '(min-width: 464px) 400px',
                'calc(100vw - 64px)'
              ]}
              alt={alt}
            />
          </ResponsiveMedia>
        </Corners>
      </div>
      <div className={styles.SplitSummaryContent}>
        <Stack>
          <Type
            className={styles.Heading}
            as="h3"
            size="heading5"
            color={titleColor || 'purple'}
          >
            {title}
          </Type>
          <Type size="small">
            <Prose color={copyColor} dangerousHtml={description} />
          </Type>
        </Stack>
      </div>
    </El>
  )
}

SplitSummary.defaultProps = {
  ratio: 3 / 4
}

SplitSummary.propTypes = {
  title: string.isRequired,
  description: string,
  image: shape({
    width: number,
    height: number,
    src: string,
    alt: string,
    srcSet: arrayOf(
      shape({
        width: number,
        src: string
      })
    )
  }),
  reverse: bool,
  url: string,
  titleColor: string,
  copyColor: string
}

export default SplitSummary
