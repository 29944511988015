import React from 'react'
import { string, number, array } from 'prop-types'

import Tile from '@/component/Primitive/Tile'

const PromoSquare = ({ title, ctaLink, src, srcSet, sizes, hoverOffset }) => (
  <Tile
    href={ctaLink}
    size="full"
    hoverOffset={hoverOffset}
    alt={title}
    src={src}
    srcSet={srcSet}
    sizes={sizes}
    width={800}
    height={800}
  />
)

PromoSquare.propTypes = {
  title: string.isRequired,
  ctaLink: string.isRequired,
  src: string.isRequired,
  srcSet: array.isRequired,
  sizes: array.isRequired,
  hoverOffset: number
}

export default PromoSquare
