import React from 'react'
import { string } from 'prop-types'

import Container from '@/component/Primitive/Container'
import Inline from '@/component/Primitive/Inline'

import Type from '@/component/Primitive/Type'

import styles from './Author.module.scss'

const Author = ({ name, bio, imageUrl }) => (
  <Container center gutter size="large">
    <Inline middle center noWrap>
      <img src={imageUrl} className={styles.AuthorImage} alt={name} />
      <Type as="p" size="small">
        <strong>By {name},</strong> {bio}
      </Type>
    </Inline>
  </Container>
)

Author.propTypes = {
  name: string,
  imageUrl: string,
  bio: string
}

export default Author
