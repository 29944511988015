import React from 'react'
import { oneOf, string } from 'prop-types'

import Container from '@/component/Primitive/Container'
import Prose from '@/component/Primitive/Prose'
import Stack from '@/component/Primitive/Stack'
import TextAlign from '@/component/Primitive/TextAlign'
import Type from '@/component/Primitive/Type'

const TypeLockup = ({
  title,
  description,
  details,
  titleSize,
  titleColor,
  copyColor
}) => (
  <Container center gutter size="mediumLarge">
    <TextAlign center>
      <Stack>
        {title && (
          <Type as="h2" size={titleSize} color={titleColor || 'purple'}>
            {title}
          </Type>
        )}
        <Stack gap="tiny">
          {description && (
            <Prose color={copyColor} dangerousHtml={description} />
          )}
          {details && (
            <Type color={copyColor || 'grey40'}>
              <Prose dangerousHtml={details} />
            </Type>
          )}
        </Stack>
      </Stack>
    </TextAlign>
  </Container>
)

TypeLockup.defaultProps = {
  titleSize: 'heading1'
}

TypeLockup.propTypes = {
  title: string,
  titleSize: oneOf(['heading1', 'heading2']),
  description: string,
  details: string,
  titleColor: string,
  copyColor: string
}

export default TypeLockup
