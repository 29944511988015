import React from 'react'
import { object, func, number } from 'prop-types'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import TextControl from '@/component/Primitive/TextControl'
import SelectControl from '@/component/Primitive/SelectControl'
import CheckControl from '@/component/Primitive/CheckControl'
import styles from './FormBuilder.module.scss'

const FormBuilderQuestion = ({ question, errors, id, register }) => {
  const options = question.responses
  const width = question.width
  const isNotText =
    question.inputType === 'checkbox' ||
    question.inputType === 'select' ||
    question.inputType === 'radio'
  const isTextarea = question.inputType === 'textarea'

  const displayOptions = (inputType) => {
    switch (inputType) {
      case 'checkbox':
        return options.map((option, i) => (
          <CheckControl
            {...register(`question-${id}`)}
            type="checkbox"
            value={option.response}
            key={`option-${i}`}
          >
            {option.response}
          </CheckControl>
        ))
      case 'select':
        return (
          <SelectControl {...register(`question-${id}`)} type="select">
            <option value="" selected disabled>
              -- Select an option --
            </option>
            {options.map((option, i) => (
              <option key={i}>{option.response}</option>
            ))}
          </SelectControl>
        )
      case 'radio':
        return options.map((option, i) => (
          <CheckControl
            {...register(`question-${id}`)}
            type="radio"
            value={option.response}
            key={`option-${i}`}
          >
            {option.response}
          </CheckControl>
        ))
    }
  }
  return (
    <div
      className={
        width === 'full'
          ? styles.WidthFull
          : width === 'half'
          ? styles.WidthHalf
          : styles.WidthQuarter
      }
    >
      <FieldTemplate
        label={question.question}
        status={errors[`question-${id}`] ? 'error' : undefined}
        feedback={errors[`question-${id}`] && errors[`question-${id}`].message}
        required={question.required}
      >
        {!isNotText ? (
          isTextarea ? (
            <TextControl
              multiLine
              maxLength={question.wordLimit}
              {...register(`question-${id}`)}
            />
          ) : (
            <TextControl {...register(`question-${id}`)} />
          )
        ) : (
          displayOptions(question.inputType)
        )}
      </FieldTemplate>
    </div>
  )
}

FormBuilderQuestion.propTypes = {
  question: object.isRequired,
  register: func.isRequired,
  errors: object.isRequired,
  id: number.isRequired
}

export default FormBuilderQuestion
