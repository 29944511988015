import React from 'react'
import { node, oneOf, string } from 'prop-types'

import CaptionedMedia from '@/component/Primitive/CaptionedMedia'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import VideoProvider from '@/component/Primitive/VideoProvider'

const InlineVideo = ({ caption, provider, videoId }) => {
  return (
    <CaptionedMedia caption={caption}>
      <ResponsiveMedia ratio={9 / 16}>
        <VideoProvider provider={provider} videoId={videoId} />
      </ResponsiveMedia>
    </CaptionedMedia>
  )
}

InlineVideo.propTypes = {
  caption: node,
  provider: oneOf(['youtube', 'vimeo']).isRequired,
  videoId: string.isRequired
}

export default InlineVideo
