import React from 'react'
import { object, func } from 'prop-types'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import TextControl from '@/component/Primitive/TextControl'
import Fieldset from '@/component/Primitive/Fieldset'

const ContactUs = ({ errors, register }) => (
  <>
    <Fieldset row>
      <FieldTemplate
        label="First Name"
        controlName="firstName"
        status={errors.firstName ? 'error' : undefined}
        feedback={errors.firstName && errors.firstName.message}
      >
        <TextControl {...register('firstName')} />
      </FieldTemplate>

      <FieldTemplate
        label="Last Name"
        controlName="lastName"
        status={errors.lastName ? 'error' : undefined}
        feedback={errors.lastName && errors.lastName.message}
      >
        <TextControl {...register('lastName')} />
      </FieldTemplate>
    </Fieldset>

    <FieldTemplate
      label="Email"
      controlName="email"
      status={errors.email ? 'error' : undefined}
      feedback={errors.email && errors.email.message}
    >
      <TextControl {...register('email')} />
    </FieldTemplate>

    <FieldTemplate
      label="Enquiry"
      controlName="enquiry"
      status={errors.enquiry ? 'error' : undefined}
      feedback={errors.enquiry && errors.enquiry.message}
    >
      <TextControl {...register('enquiry')} multiLine />
    </FieldTemplate>
  </>
)

ContactUs.propTypes = {
  errors: object.isRequired,
  register: func.isRequired
}

export default ContactUs
