import React from 'react'
import { string, bool, array, object } from 'prop-types'

import styles from './Product.module.scss'

import ResponsiveImage from '../ResponsiveImage'
import ResponsiveMedia from '../ResponsiveMedia'
import Type from '../Type'

const Product = ({
  link,
  title,
  image,
  description,
  versionType,
  showPricing,
  widgetOptions,
  pricingDetails,
  showDescription,
  showTitleAndVersionType
}) => {
  const showPricingDetails =
    widgetOptions.showProductPricingDetails && showPricing
  const showDescriptions =
    widgetOptions.showProductDescriptions && showDescription
  const showTitleAndVersionTypes =
    widgetOptions.showProductTitlesAndVersionTypes && showTitleAndVersionType
  const productHasPriceReduction =
    pricingDetails && pricingDetails.some((product) => product.previousPrice)

  return (
    <a className={styles.Product} href={link}>
      <div className={styles.ProductImage}>
        <div className={styles.ProductImageInner}>
          <ResponsiveMedia ratio={1}>
            <ResponsiveImage alt={title} src={image} width={400} height={400} />
          </ResponsiveMedia>
        </div>
      </div>
      {showTitleAndVersionTypes && (
        <div className={styles.ProductTitleWrapper}>
          <Type size="heading4">{title}</Type>
          <Type color="grey40" size="small">
            {versionType}
          </Type>
        </div>
      )}
      {showDescriptions && (
        <div
          className={styles.ProductDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {showPricingDetails && (
        <>
          {productHasPriceReduction && <Type as="span">Limited offer </Type>}
          <Type as="span" size="small" color="grey40">
            {productHasPriceReduction ? 'from' : 'From'}
          </Type>
          {pricingDetails.length > 0 &&
            pricingDetails.map((pricing, i) => (
              <div key={`Product--PricingDetails--${i}`}>
                <Type as="span" weight="bold">
                  {pricing.price}
                </Type>{' '}
                <Type as="span" size="small" color="grey40">
                  {pricing.billingType}
                </Type>
                {pricing.previousPrice && (
                  <>
                    <Type as="span" size="small" color="grey40">
                      {' '}
                      (was
                      <Type as="span" size="base">
                        {' '}
                        <strike>{pricing.previousPrice}</strike>
                      </Type>
                      )
                    </Type>
                  </>
                )}
              </div>
            ))}
        </>
      )}
    </a>
  )
}

Product.propTypes = {
  description: string,
  link: string.isRequired,
  image: string.isRequired,
  title: string.isRequired,
  showPricing: bool,
  versionType: string,
  pricingDetails: array,
  showDescription: bool,
  showTitleAndVersionType: bool,
  widgetOptions: object
}

export default Product
