import React, { useEffect, useState } from 'react'
import { node, bool, string } from 'prop-types'

import Banner from '@/component/Primitive/Banner'
import SpacingReverser from '@/component/Primitive/SpacingReverser'

const BannerWidget = ({ content, displayOnce, id, titleColor }) => {
  const [dismissed, setDismissed] = useState(displayOnce)

  useEffect(() => {
    if (!displayOnce) return

    setDismissed(!!localStorage.getItem(`banner-${id}`))
    localStorage.setItem(`banner-${id}`, 'true')
  }, [displayOnce, id])

  if (dismissed) return null

  return (
    <SpacingReverser main>
      <Banner>
        <div
          style={{ color: titleColor }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Banner>
    </SpacingReverser>
  )
}

BannerWidget.propTypes = {
  content: node,
  displayOnce: bool,
  id: string,
  titleColor: string
}

export default BannerWidget
