import React from 'react'
import { string } from 'prop-types'

import SuperHero from '@/component/Primitive/SuperHero'
import SpacingReverser from '@/component/Primitive/SpacingReverser'

const SuperHeroWidget = (props) => {
  return (
    <SpacingReverser main>
      <SuperHero {...props} />
    </SpacingReverser>
  )
}

SuperHeroWidget.propTypes = {
  backgroundColor: string,
  kioskTopLabel: string,
  kioskTopLink: string,
  kioskBottomLabel: string,
  kioskBottomLink: string,
  leftBuoyLabel: string,
  leftBuoyLink: string,
  rightBuoyLabel: string,
  rightBuoyLink: string
}

export default SuperHeroWidget
