import React from 'react'
import { node } from 'prop-types'

const FaqList = ({ children }) => <ol>{children}</ol>

FaqList.propTypes = {
  children: node.isRequired
}

export default FaqList
