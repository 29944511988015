import React from 'react'
import { array, string } from 'prop-types'

import ActionRow from '../ActionRow'
import ShapedContainer from '../../Structure/ShapedContainer'
import ButtonOutline from '@/component/Primitive/ButtonOutline'
import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Container from '@/component/Primitive/Container'
import Hero from '@/component/Primitive/Hero'
import Prose from '@/component/Primitive/Prose'
import Spacer from '@/component/Primitive/Spacer'
import Stack from '@/component/Primitive/Stack'
import TextAlign from '@/component/Primitive/TextAlign'
import Type from '@/component/Primitive/Type'

const TextHero = ({
  title,
  description,
  details,
  srcSet,
  ctaPrimaryText,
  ctaPrimaryLink,
  ctaSecondaryText,
  ctaSecondaryLink,
  titleColor,
  copyColor
}) => (
  <Hero ratio={1 / 2} srcSet={srcSet} cappedHeight>
    <Container center gutter size="medium">
      <TextAlign center>
        <ShapedContainer shape="rounded">
          <Stack>
            {title && (
              <Type as="h2" size="heading1" color={titleColor || 'white'}>
                {title}
              </Type>
            )}
            <Spacer mb={2}>
              <Stack gap="tiny">
                {description && (
                  <Prose
                    inverse
                    dangerousHtml={description}
                    color={copyColor}
                  />
                )}
                {details && (
                  <Type color="white">
                    <Prose inverse dangerousHtml={details} color={copyColor} />
                  </Type>
                )}
              </Stack>
            </Spacer>

            <ActionRow>
              {ctaSecondaryLink && ctaSecondaryText && (
                <ButtonOutline accent inverse href={ctaSecondaryLink}>
                  {ctaSecondaryText}
                </ButtonOutline>
              )}
              {ctaPrimaryLink && ctaPrimaryText && (
                <ButtonStandard accent href={ctaPrimaryLink}>
                  {ctaPrimaryText}
                </ButtonStandard>
              )}
            </ActionRow>
          </Stack>
        </ShapedContainer>
      </TextAlign>
    </Container>
  </Hero>
)

TextHero.propTypes = {
  title: string,
  description: string,
  details: string,
  srcSet: array.isRequired,
  ctaPrimaryText: string,
  ctaPrimaryLink: string,
  ctaSecondaryText: string,
  ctaSecondaryLink: string,
  titleColor: string,
  copyColor: string
}

export default TextHero
