import React from 'react'
import { node } from 'prop-types'

import styles from './Banner.module.scss'

const Banner = ({ children }) => (
  <div className={styles.Banner}>
    <div>{children}</div>
  </div>
)

Banner.propTypes = {
  children: node.isRequired
}

export default Banner
