import React from 'react'
import { array } from 'prop-types'

import Carousel from '@/component/Primitive/Carousel'
import Container from '@/component/Primitive/Container'
import InlineImage from '@/component/Composition/InlineImage'

const InlineImageWidget = ({ inlineImageImages }) => {
  const formattedImages = inlineImageImages.map((image) => {
    const { alt, caption, link } = Object.values(image.images)[0][0]
    const getImage = ({ width, ratio }) =>
      image.images?.[`inline_${ratio}_${width}`]?.[0]

    const getImageData = ({ ratio }) => {
      if (ratio === '16:9') {
        const primaryImage = getImage({ width: 832, ratio: '16x9' })
        return {
          src: primaryImage.url,
          width: primaryImage.width,
          height: primaryImage.height,
          srcSet: [
            { width: 1664, src: getImage({ width: 1664, ratio: '16x9' }).url }, // 2x
            { width: 832, src: primaryImage.url },
            { width: 600, src: getImage({ width: 600, ratio: '16x9' }).url },
            { width: 400, src: getImage({ width: 400, ratio: '16x9' }).url }
          ],
          sizes: ['(min-width: 960px) 832px', 'calc(100vw - 64px)']
        }
      }
      if (ratio === '1:1') {
        const primaryImage = getImage({ width: 468, ratio: '1x1' })
        return {
          src: primaryImage.url,
          width: primaryImage.width,
          height: primaryImage.height,
          srcSet: [
            { width: 936, src: getImage({ width: 936, ratio: '1x1' }).url }, // 2x
            { width: 468, src: primaryImage.url },
            { width: 400, src: getImage({ width: 400, ratio: '1x1' }).url }
          ],
          sizes: ['(min-width: 960px) 468px', 'calc((100vw - 64px) * 0.5625)']
        }
      }
      if (ratio === 'Free') {
        const primaryImage = getImage({ width: 832, ratio: 'free' })
        return {
          src: primaryImage.url,
          width: primaryImage.width,
          height: primaryImage.height
        }
      }
      return []
    }

    const imageData = getImageData({ ratio: image.crop })
    return {
      alt: alt || '',
      caption,
      src: imageData.src,
      width: imageData.width,
      height: imageData.height,
      srcSet: imageData.srcSet,
      sizes: imageData.sizes,
      link: link
    }
  })

  if (formattedImages.length > 1) {
    return (
      <Container center gutter size="mediumLarge">
        <Carousel showPageCount bottomControls>
          {formattedImages.map((image, i) => {
            return (
              <InlineImage
                key={`InlineImage:Image:${i}`}
                {...image}
                frameRatio={9 / 16}
                ratio={image.height / image.width}
                safeAreaRight="150px"
                carousel
              />
            )
          })}
        </Carousel>
      </Container>
    )
  }

  const image = formattedImages[0]
  const { width, height } = image

  return (
    <Container center gutter size="mediumLarge">
      <InlineImage {...image} ratio={height / width} />
    </Container>
  )
}

InlineImageWidget.propTypes = {
  inlineImageImages: array // TODO: shape
}

export default InlineImageWidget
