import { generatePageError } from 'next-with-error'
import retrieveData from './retrieve-data'

const retrieveAndProcessDataForPage = async (context, query, args) => {
  const { data, vars } = await retrieveData(context, query, args)
  if (!data || !data.resource) {
    return generatePageError(404, {
      instance: data && data.instance
    })
  }
  if (data.resource.__typename === 'Redirect' && window) {
    window.location = data.resource.redirectUrl
  } else {
    data.vars = vars
    return { response: data, vars }
  }
}

export default retrieveAndProcessDataForPage
