import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './FieldRequired.module.scss'

const FieldRequired = () => {
  const { t } = useTranslation()
  return (
    <abbr className={styles.FieldRequired} title={t('FormFieldRequired')}>
      *
    </abbr>
  )
}

export default FieldRequired
