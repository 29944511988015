import React from 'react'
import FormBuilderQuestion from './FormBuilderQuestion'
import { string, bool, object, func } from 'prop-types'

import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Stack from '@/component/Primitive/Stack'
import TextAlign from '@/component/Primitive/TextAlign'
import Type from '@/component/Primitive/Type'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import CheckControl from '@/component/Primitive/CheckControl'
import Notification from '@/component/Primitive/Notification'
import Rule from '@/component/Primitive/Rule'
import Container from '@/component/Primitive/Container'
import styles from './FormBuilder.module.scss'

const FormBuilder = ({
  onSubmit,
  error,
  errors,
  loading,
  success,
  register,
  formBuilder,
  __url
}) => {
  const { questions, thankyouMessage } = formBuilder

  return (
    <Container center gutter size="medium" className={styles.FormBuilder}>
      <Stack>
        <TextAlign center>
          <Type as="h3" size="heading3">
            {formBuilder.title}
          </Type>
        </TextAlign>

        <form onSubmit={onSubmit} action="/api/form-builder" method="post">
          <Stack gap="small">
            {questions &&
              questions.map((question, i) => (
                <FormBuilderQuestion
                  key={`question-${i}`}
                  question={question}
                  errors={errors}
                  register={register}
                  id={i}
                />
              ))}

            <Rule />

            <Stack as="div" gap="large">
              <FieldTemplate
                label="Terms"
                controlName="termsAgreed"
                status={errors.termsAgreed ? 'error' : undefined}
                feedback={errors.termsAgreed && errors.termsAgreed.message}
              >
                <CheckControl type="checkbox" {...register('termsAgreed')}>
                  I agree to the{' '}
                  <a
                    href={__url('/shop/data-privacy-legal/terms-conditions')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    terms and conditions
                  </a>{' '}
                  &{' '}
                  <a
                    href={__url('/shop/data-privacy-legal ')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy policy
                  </a>
                </CheckControl>
              </FieldTemplate>
            </Stack>

            {error && (
              <Stack gap="small">
                <Notification status="error">
                  <Type size="small">Please review your answers above.</Type>
                </Notification>
              </Stack>
            )}
            {success && (
              <Notification status="success">
                <Type size="small">{thankyouMessage}</Type>
              </Notification>
            )}

            <ButtonStandard block type="submit" disabled={loading || success}>
              {loading ? 'Loading…' : 'Submit'}
            </ButtonStandard>
          </Stack>
        </form>
      </Stack>
    </Container>
  )
}

FormBuilder.propTypes = {
  onSubmit: func.isRequired,
  register: func.isRequired,
  success: bool,
  loading: bool,
  error: string,
  errors: object.isRequired,
  formBuilder: string.isRequired,
  __url: func.isRequired
}

export default FormBuilder
