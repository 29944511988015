import React from 'react'
import { string } from 'prop-types'

import TypeLockup from '@/component/Composition/TypeLockup'

const HeaderWidget = ({ title, subtitle, titleColor, copyColor }) => {
  return (
    <TypeLockup
      title={title}
      description={subtitle}
      titleColor={titleColor}
      copyColor={copyColor}
    />
  )
}
HeaderWidget.propTypes = {
  title: string.isRequired,
  subtitle: string,
  titleColor: string,
  copyColor: string
}

export default HeaderWidget
