import React from 'react'
import classNames from 'classnames'
import { array, string } from 'prop-types'

import Grid from '../Grid'
import Stack from '../Stack'
import Fieldset from '../Fieldset'
import Container from '../Container'
import TextAlign from '../TextAlign'
import ButtonStandard from '../ButtonStandard'

import styles from './GettingStartedContent.module.scss'

const GettingStartedContent = ({ links, linksTitle, titleColor }) => {
  return (
    <Container center gutter size="large">
      <TextAlign center>
        <Stack gap="large">
          <Fieldset
            legend={linksTitle}
            fontSize="large"
            legendcolor={titleColor}
          >
            <Grid gutter="double" centre flex>
              {links.map((link, i) => (
                <Grid.Item
                  width={{ m: 1 / 2, d: 1 / 3 }}
                  trailingGap="xlarge"
                  key={`supportOption:${i}`}
                >
                  <ButtonStandard
                    href={link.link}
                    light
                    color={link.linkColor}
                    className={classNames(
                      styles.optionButton,
                      styles.fullWidth,
                      styles.fullHeight
                    )}
                  >
                    {link.label}
                  </ButtonStandard>
                </Grid.Item>
              ))}
            </Grid>
          </Fieldset>
        </Stack>
      </TextAlign>
    </Container>
  )
}

GettingStartedContent.propTypes = {
  links: array,
  linksTitle: string,
  titleColor: string
}

export default GettingStartedContent
