import React from 'react'
import { array, func, string } from 'prop-types'

import SpacingReverser from '@/component/Primitive/SpacingReverser'
import GettingStartedContent from '@/component/Primitive/GettingStartedContent'

const GettingStartedContentWidget = ({
  __url,
  links,
  linksTitle,
  titleColor
}) => {
  const embellishedLinks = links.map((link) => ({
    label: link.label,
    link: __url(link.link),
    linkColor: link.linkColor
  }))

  return (
    <SpacingReverser panel>
      <GettingStartedContent
        linksTitle={linksTitle}
        titleColor={titleColor}
        links={embellishedLinks}
      />
    </SpacingReverser>
  )
}

GettingStartedContentWidget.propTypes = {
  links: array,
  linksTitle: string,
  titleColor: string,
  __url: func.isRequired
}

export default GettingStartedContentWidget
