import { shape, string } from 'prop-types'
import React from 'react'

import Author from '@/component/Primitive/Author'
import Container from '@/component/Primitive/Container'
import TextAlign from '@/component/Primitive/TextAlign'
import Type from '@/component/Primitive/Type'

const ArticleLayoutHeader = ({ article }) => {
  return (
    <>
      <Container center size="large">
        <TextAlign center>
          <Type as="h2" size="heading1" color="purple">
            {article.headline}
          </Type>
        </TextAlign>
      </Container>
      {article.author && (
        <Author
          name={article.author.name}
          bio={article.author.biography}
          imageUrl={article.author.images.main_1x1_150[0].url}
        />
      )}
    </>
  )
}

ArticleLayoutHeader.propTypes = {
  article: shape({
    headline: string
  })
}

export default ArticleLayoutHeader
