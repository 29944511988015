import React from 'react'
import { node, oneOf } from 'prop-types'
import classNames from 'classnames'

import Spacer from '@/component/Primitive/Spacer'

import styles from './ShapedContainer.module.scss'

const shapes = ['bevelled', 'rounded']
const ShapedContainer = ({ children, shape }) => (
  <div className={classNames(styles.ShapedContainer, shape && styles[shape])}>
    <Spacer p={4} mb={4}>
      {children}
    </Spacer>
  </div>
)

ShapedContainer.propTypes = {
  children: node,
  shape: oneOf(shapes)
}

export default ShapedContainer
