import React, { useContext } from 'react'
import { string } from 'prop-types'

import { RowContext } from '@/component/Context/RowContext'

import Promo from '@/component/Composition/Promo'
import SpacingReverser from '@/component/Primitive/SpacingReverser'

const PromoWidget = ({
  title,
  text,
  ctaLink,
  ctaText,
  titleColor,
  copyColor
}) => {
  const contextRowType = useContext(RowContext)

  if (contextRowType === 'panelled') {
    return (
      <SpacingReverser panel>
        <Promo
          title={title}
          text={text}
          ctaLink={ctaLink}
          ctaText={ctaText}
          titleColor={titleColor}
          copyColor={copyColor}
        />
      </SpacingReverser>
    )
  }

  return (
    <Promo
      corners
      title={title}
      text={text}
      ctaLink={ctaLink}
      ctaText={ctaText}
    />
  )
}

PromoWidget.propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  ctaLink: string.isRequired,
  ctaText: string.isRequired,
  titleColor: string,
  copyColor: string
}

export default PromoWidget
