import React from 'react'
import { string } from 'prop-types'

import ActionRow from '@/component/Composition/ActionRow'
import ButtonStandard from '@/component/Primitive/ButtonStandard'
import ButtonOutline from '@/component/Primitive/ButtonOutline'
import Container from '@/component/Primitive/Container'

const CtaWidget = ({ link, text, secondaryLink, secondaryText }) => {
  const isExternal = (url) => !!url.match(/^https?:/)
  return (
    <Container center gutter size="mediumLarge">
      <ActionRow>
        {secondaryLink && (
          <ButtonOutline
            accent
            {...(isExternal(secondaryLink)
              ? { href: secondaryLink }
              : { to: secondaryLink })}
          >
            {secondaryText}
          </ButtonOutline>
        )}
        <ButtonStandard
          accent
          {...(isExternal(link) ? { href: link } : { to: link })}
        >
          {text}
        </ButtonStandard>
      </ActionRow>
    </Container>
  )
}

CtaWidget.propTypes = {
  text: string.isRequired,
  link: string.isRequired,
  secondaryText: string,
  secondaryLink: string
}

export default CtaWidget
