import HelpAndSupport from './HelpAndSupport'
import SchoolPagesSales from './SchoolPagesSales'
import RegisterInterest from './RegisterInterest'
import ContactUs from './ContactUs'
import HairyReadingFreeTrial from './HairyReadingFreeTrial'
import TrainingRsvp from './TrainingRsvp'
import DyslexiaAwareBadge from './DyslexiaAwareBadge'

const fieldsets = {
  'Help & Support': HelpAndSupport,
  'School Pages Sales': SchoolPagesSales,
  'Register Interest': RegisterInterest,
  'Contact Us': ContactUs,
  'Hairy Reading - Free Trial': HairyReadingFreeTrial,
  'Training RSVP': TrainingRsvp,
  'Dyslexia Aware Badge': DyslexiaAwareBadge
}

export default fieldsets
