import React, { useContext, forwardRef } from 'react'
import { bool, oneOf } from 'prop-types'
import classNames from 'classnames'

import { StatusContext } from '@/component/Context/StatusContext'

import styles from './CustomCheckControl.module.scss'

import NativeCheckControl from '../NativeCheckControl'

const CustomCheckControl = forwardRef(
  ({ status, type, inverse, ...other }, ref) => {
    const contextStatus = useContext(StatusContext)
    const derivedStatus = status || contextStatus
    const componentClassName = classNames(
      styles.CustomCheckControl,
      type && styles[type],
      derivedStatus && styles[derivedStatus],
      inverse && styles.inverse
    )

    return (
      <span className={componentClassName}>
        <NativeCheckControl
          className={styles.CustomCheckControlControl}
          type={type}
          ref={ref}
          {...other}
        />
        <span className={styles.CustomCheckControlIndicator} />
      </span>
    )
  }
)

CustomCheckControl.displayName = 'CustomCheckControl'

CustomCheckControl.propTypes = {
  status: oneOf(['none', 'error', 'notice', 'success', 'warning']),
  type: oneOf(['checkbox', 'radio']).isRequired,
  inverse: bool
}

export default CustomCheckControl
