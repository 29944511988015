import React from 'react'
import { bool, node, object } from 'prop-types'

import { RowContextProvider } from '@/component/Context/RowContext'
import Container from '@/component/Primitive/Container'
import Panel from '@/component/Primitive/Panel'
import Stack from '@/component/Primitive/Stack'

const Row = ({ row, children, isArticle }) => {
  const rowType = row.attributes && row.attributes[0]

  const hasWidgets = row.cols.some((col) => col.widgetArea?.widgets?.length > 0)
  if (!hasWidgets) return null

  const rowTypes = {
    panelled: (
      <Container center gutter size="wide">
        <Panel>
          <Stack gap={isArticle ? 'medium' : 'large'}>{children}</Stack>
        </Panel>
      </Container>
    ),
    narrowPanelled: (
      <Container center gutter size="wide">
        <Container center gutter size="large">
          <Panel>
            <Stack gap={isArticle ? 'medium' : 'large'}>{children}</Stack>
          </Panel>
        </Container>
      </Container>
    ),
    full: <Container>{children}</Container>,
    standard: (
      <Container center gutter size="wide">
        <Stack gap="large">{children}</Stack>
      </Container>
    )
  }

  return (
    <RowContextProvider type={rowType}>
      {rowTypes[rowType] || null}
    </RowContextProvider>
  )
}

Row.propTypes = {
  row: object.isRequired,
  children: node,
  isArticle: bool
}

export default Row
