import React from 'react'
import { node } from 'prop-types'

import Inline from '@/component/Primitive/Inline'
import TextAlign from '@/component/Primitive/TextAlign'

const TileGrid = ({ children }) => (
  <TextAlign center>
    <Inline shrink center gap="large-responsive">
      {children}
    </Inline>
  </TextAlign>
)

TileGrid.propTypes = {
  children: node
}

export default TileGrid
