import React, { useState } from 'react'
import { arrayOf, func, shape, string, object } from 'prop-types'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import styles from './FilterPanel.module.scss'

import ButtonStandard from '../ButtonStandard'
import CheckControl from '../CheckControl'
import Container from '../Container'
import Fieldset from '../Fieldset'
import PanelAlt from '../PanelAlt'
import Prose from '../Prose'
import Stack from '../Stack'
import TextAlign from '../TextAlign'
import Type from '../Type'

const FilterPanel = ({
  onChange,
  title,
  description,
  action,
  supportOptionsTitle,
  supportOptions,
  ageGroupsTitle,
  ageGroups,
  ctaText,
  limits = {}
}) => {
  const [internalSupportOptions, updateInternalSupportOptions] = useState([])
  const [internalAgeGroup, updateInternalAgeGroup] = useState()
  const [submitted, setSubmitted] = useState(false)
  const router = useRouter()

  const handleSupportOptionsChange = (e) => {
    const newValues = e.target.checked
      ? [...internalSupportOptions, e.target.value]
      : [...internalSupportOptions].filter((item) => item !== e.target.value)
    updateInternalSupportOptions(newValues)
    onChange && onChange(e)
  }

  const handleAgeGroupChange = (e) => {
    updateInternalAgeGroup(e.target.value)
    onChange && onChange(e)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)

    const query = {
      skills: internalSupportOptions.join('+'),
      age: internalAgeGroup
    }

    if (limits.articleLimit) query.articleLimit = limits.articleLimit
    if (limits.strategyLimit) query.strategyLimit = limits.strategyLimit

    router.push({
      pathname: action,
      query
    })
  }

  return (
    <form
      action={action}
      method="get"
      onSubmit={handleSubmit}
      className={styles.FilterPanel}
    >
      <PanelAlt>
        <Container center gutter size="medium">
          <TextAlign center>
            <Stack gap="large">
              <Stack>
                <Type as="h2" size="heading2" color="yellow">
                  {title}
                </Type>
                {description && <Prose dangerousHtml={description} />}
              </Stack>
              <Fieldset legend={supportOptionsTitle}>
                <div className={styles.FilterPanelOptions}>
                  {supportOptions.map((option) => (
                    <div key={`supportOption:${option.value}`}>
                      <CheckControl
                        name="supportOptions"
                        value={option.value}
                        type="checkbox"
                        onChange={handleSupportOptionsChange}
                        inverse
                      >
                        {option.label}
                      </CheckControl>
                    </div>
                  ))}
                </div>
              </Fieldset>
              <Fieldset legend={ageGroupsTitle}>
                <div
                  className={classNames(
                    styles.FilterPanelOptions,
                    styles.FilterPanelOptionsCentered
                  )}
                >
                  {ageGroups.map((option) => (
                    <div key={`ageGroup:${option.value}`}>
                      <CheckControl
                        name="ageGroups"
                        value={option.value}
                        type="radio"
                        onChange={handleAgeGroupChange}
                        inverse
                      >
                        {option.label}
                      </CheckControl>
                    </div>
                  ))}
                </div>
              </Fieldset>
              <ButtonStandard
                type="submit"
                loading={submitted}
                disabled={!(internalSupportOptions.length && internalAgeGroup)}
              >
                {ctaText}
              </ButtonStandard>
            </Stack>
          </TextAlign>
        </Container>
      </PanelAlt>
    </form>
  )
}

FilterPanel.defaultProps = {
  action: ''
}

FilterPanel.propTypes = {
  action: string,
  onChange: func,
  title: string.isRequired,
  description: string,
  supportOptionsTitle: string.isRequired,
  supportOptions: arrayOf(shape({ name: string, value: string })).isRequired,
  ageGroupsTitle: string.isRequired,
  ageGroups: arrayOf(shape({ name: string, value: string })).isRequired,
  ctaText: string.isRequired,
  limits: object
}

export default FilterPanel
