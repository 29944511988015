const createQuery = () => `
  ... on TextHeroWidget {
    id
    images {
      hero_2x1_600 {
        url
        caption
        link
      }
      hero_2x1_800 {
        url
        caption
        link
      }
      hero_2x1_1000 {
        url
        caption
        link
      }
      hero_2x1_1280 {
        url
        caption
        link
      }
      hero_2x1_1600 {
        url
        caption
        link
      }
      hero_2x1_2000 {
        url
        caption
        link
      }
      hero_2x1_2500 {
        url
        caption
        link
      }
    }
    title
    description
    details
    primaryText
    primaryLink
    secondaryText
    secondaryLink
  }
`
export default createQuery
