import React from 'react'
import { bool, node, string } from 'prop-types'

import styles from './CaptionedMedia.module.scss'

const CaptionedMedia = ({
  caption,
  children,
  figure,
  safeAreaLeft,
  safeAreaRight
}) => {
  const BodyEl = figure ? 'figure' : 'div'
  const CaptionEl = figure ? 'figcaption' : 'div'

  if (!caption) return children

  return (
    <div className={styles.CaptionedMedia}>
      <BodyEl className={styles.CaptionedMediaBody}>{children}</BodyEl>
      {caption && (
        <CaptionEl
          className={styles.CaptionedMediaCaption}
          style={{
            ...(safeAreaLeft && { paddingLeft: safeAreaLeft }),
            ...(safeAreaRight && { paddingRight: safeAreaRight })
          }}
        >
          {caption}
        </CaptionEl>
      )}
    </div>
  )
}

CaptionedMedia.propTypes = {
  caption: node,
  children: node.isRequired,
  figure: bool,
  safeAreaLeft: string,
  safeAreaRight: string
}

export default CaptionedMedia
