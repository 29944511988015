const generateLink = (product) => {
  if (!product) return

  const productSlug = product?.slug
  const instanceSlug = product?.instance?.[0]?.slug

  return `/${instanceSlug}/product/${productSlug}`
}

export default generateLink
