import React from 'react'
import { arrayOf, bool, object, string } from 'prop-types'

import ActionRow from '../ActionRow'

import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Card from '@/component/Primitive/Card'
import CardGrid from '@/component/Primitive/CardGrid'
import CardGroup from '@/component/Primitive/CardGroup'
import Container from '@/component/Primitive/Container'
import Stack from '@/component/Primitive/Stack'
import TextAlign from '@/component/Primitive/TextAlign'
import Type from '@/component/Primitive/Type'

const EmbellishedCardGrid = ({
  ctaLink,
  ctaText,
  inlineMobile,
  items,
  subtitle,
  title,
  titleColor,
  copyColor,
  square
}) => {
  const El = inlineMobile ? CardGroup : CardGrid
  return (
    <Container center gutter={!inlineMobile} size="large">
      <Stack gap="medium">
        {(title || subtitle) && (
          <Container gutter={inlineMobile}>
            <TextAlign center>
              <Stack>
                {title && (
                  <Type as="h2" size="heading2" color={titleColor || 'purple'}>
                    {title}
                  </Type>
                )}
                {subtitle && (
                  <Type as={title ? 'h3' : 'h2'} size="base" color={copyColor}>
                    {subtitle}
                  </Type>
                )}
              </Stack>
            </TextAlign>
          </Container>
        )}
        <El>
          {items.map((item, i) => (
            <Card
              square={square}
              key={`CardGrid:Card:${i}`}
              to={item.link || item.slug}
              title={item.title}
              description={item.sell}
              src={item.src}
              srcSet={item.srcSet}
              titleColor={titleColor}
              copyColor={copyColor}
              sizes={[
                '(min-width: 1180px) 310px',
                '(min-width: 960px) calc(33vw - 75px)',
                '(min-width: 800px) calc(50vw - 56px)',
                '(min-width: 400px) calc(50vw - 40px)',
                'calc(100vw - 64px)'
              ]}
            />
          ))}
        </El>
        {ctaLink && ctaText && (
          <Container gutter={inlineMobile}>
            <ActionRow>
              <ButtonStandard accent href={ctaLink}>
                {ctaText}
              </ButtonStandard>
            </ActionRow>
          </Container>
        )}
      </Stack>
    </Container>
  )
}

EmbellishedCardGrid.defaultProps = {
  items: []
}

EmbellishedCardGrid.propTypes = {
  ctaLink: string,
  ctaText: string,
  inlineMobile: bool,
  items: arrayOf(object).isRequired, // TODO: shape
  subtitle: string,
  title: string,
  titleColor: string,
  copyColor: string,
  square: bool
}

export default EmbellishedCardGrid
