import React from 'react'
import { arrayOf, shape, string, oneOf } from 'prop-types'
import { useTranslation } from 'react-i18next'

import IconButton from '@/component/Primitive/IconButton'
import Type from '@/component/Primitive/Type'

import styles from './SocialShare.module.scss'

const iconMap = {
  facebook: 'facebook',
  twitter: 'twitter'
}

const SocialShare = ({ links }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.SocialShare}>
      <Type className={styles.SocialShareHeading} as="h4" size="small">
        {t('ShareTitle')}
      </Type>
      <ul className={styles.SocialShareList}>
        {links?.map((link, i) => {
          if (!iconMap[link.type]) return null
          return (
            <li
              className={styles.SocialShareItem}
              key={`SocialIcon-${link.type}-${i}`}
            >
              <IconButton
                icon={iconMap[link.type]}
                href={link.url}
                a11yText={`Visit us on ${link.type}`}
                light
                rounded
                solid
                target="_blank"
                rel="noopener noreferrer"
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

SocialShare.propTypes = {
  links: arrayOf(
    shape({
      type: oneOf(Object.keys(iconMap)),
      url: string
    })
  )
}

export default SocialShare
