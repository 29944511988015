import React from 'react'
import { node } from 'prop-types'

import styles from './ProductGrid.module.scss'

const ProductGrid = ({ children }) => (
  <div className={styles.ProductGrid}>
    <div className={styles.ProductGridInner}>{children}</div>
  </div>
)

ProductGrid.propTypes = {
  children: node.isRequired
}

export default ProductGrid
