import React from 'react'
import { node } from 'prop-types'

import Inline from '@/component/Primitive/Inline'
import TextAlign from '@/component/Primitive/TextAlign'

const ActionRow = ({ children }) => (
  <TextAlign center>
    <Inline shrink>{children}</Inline>
  </TextAlign>
)

ActionRow.propTypes = {
  children: node
}

export default ActionRow
