import React from 'react'
import { node } from 'prop-types'

import Grid from '@/component/Primitive/Grid'

const PromoDouble = ({ primary, secondary }) => (
  <Grid gutter="double" flex>
    <Grid.Item width={[1, 1 / 2]} grow trailingGap="mobile">
      {primary}
    </Grid.Item>
    <Grid.Item width={[1, 1 / 2]} grow>
      {secondary}
    </Grid.Item>
  </Grid>
)

PromoDouble.propTypes = {
  primary: node,
  secondary: node
}

export default PromoDouble
