import imageProperties from '../lib/image-properties'

const createQuery = () => `
  ... on ImageWithTextWidget {
    title
    a11yTitle
    text
    reverse
    crop
    url
    images {
      main_16x9_1092 {
        ${imageProperties}
      }
      main_16x9_800 {
        ${imageProperties}
      }
      main_16x9_546 {
        ${imageProperties}
      }
      main_16x9_400 {
        ${imageProperties}
      }
      main_4x3_1092 {
        ${imageProperties}
      }
      main_4x3_800 {
        ${imageProperties}
      }
      main_4x3_546 {
        ${imageProperties}
      }
      main_4x3_400 {
        ${imageProperties}
      }
    }
  }
`
export default createQuery
