import React from 'react'
import { func, array, object } from 'prop-types'

import Row from '../Row'
import Column from '../Column'
import WidgetArea from '../WidgetArea'
import Stack from '@/component/Primitive/Stack'

const Layout = ({ layout, __url, article, section, pageData }) => {
  return (
    <Stack gap="large">
      {layout.map((row, rowIndex) => (
        <Row key={rowIndex} row={row} isArticle={!!article}>
          {row.cols.map((column, columnIndex) => (
            <Column key={columnIndex} column={column}>
              <WidgetArea
                widgets={column.widgetArea.widgets}
                __url={__url}
                article={article}
                section={section}
                pageData={pageData}
              />
            </Column>
          ))}
        </Row>
      ))}
    </Stack>
  )
}

Layout.propTypes = {
  layout: array,
  __url: func.isRequired,
  article: object,
  section: object,
  pageData: object
}

export default Layout
