import React from 'react'
import WidgetArea from '@/component/Structure/WidgetArea'
import { func, object } from 'prop-types'

const ArticleLayoutBody = ({ article, section, pageData, __url }) => {
  const articleBodyWidgets = article?.body?.widgets
  return (
    <WidgetArea
      widgets={articleBodyWidgets}
      article={article}
      section={section}
      pageData={pageData}
      __url={__url}
    />
  )
}

ArticleLayoutBody.propTypes = {
  section: object,
  article: object,
  pageData: object,
  __url: func.isRequired
}

export default ArticleLayoutBody
