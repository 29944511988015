import React from 'react'
import { node } from 'prop-types'

import styles from './CardGroup.module.scss'

import ScrollArea from '../ScrollArea'

const CardGroup = ({ children }) => (
  <div className={styles.CardGroup}>
    <ScrollArea horizontal hideScrollbar>
      <div className={styles.CardGroupInner}>
        <ul className={styles.CardGroupList}>{children}</ul>
      </div>
    </ScrollArea>
  </div>
)

CardGroup.propTypes = {
  children: node.isRequired
}

export default CardGroup
