import React from 'react'
import { string, object, func } from 'prop-types'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import TextControl from '@/component/Primitive/TextControl'
import CheckControl from '@/component/Primitive/CheckControl'
import SelectControl from '@/component/Primitive/SelectControl'
import Fieldset from '@/component/Primitive/Fieldset'
import Stack from '@/component/Primitive/Stack'

const SchoolPagesSales = ({ country, errors, watch, register }) => {
  const accountType = watch('accountType')
  const existingCustomer = watch('existingCustomer')
  const program = watch('program')
  const programs = [
    'Nessy Reading and Spelling',
    'Hairy Reading',
    'Nessy Numbersense',
    'Writing Beach',
    'Dyslexia Quest screener',
    'Nessy Fingers touch typing',
    'Understanding Dyslexia training course',
    'Apple, Amazon and Play Store apps',
    'Books, bags and card games',
    'Other – Please tell us which program you would like to know more about'
  ]
  return (
    <>
      <Fieldset row>
        <FieldTemplate
          label="First Name"
          controlName="firstName"
          status={errors.firstName ? 'error' : undefined}
          feedback={errors.firstName && errors.firstName.message}
        >
          <TextControl {...register('firstName')} />
        </FieldTemplate>

        <FieldTemplate
          label="Last Name"
          controlName="lastName"
          status={errors.lastName ? 'error' : undefined}
          feedback={errors.lastName && errors.lastName.message}
        >
          <TextControl {...register('lastName')} />
        </FieldTemplate>
      </Fieldset>

      <FieldTemplate
        label="Email"
        controlName="email"
        status={errors.email ? 'error' : undefined}
        feedback={errors.email && errors.email.message}
      >
        <TextControl {...register('email')} />
      </FieldTemplate>

      <FieldTemplate
        label="Account Type"
        controlName="accountType"
        status={errors.accountType ? 'error' : undefined}
        feedback={errors.accountType && errors.accountType.message}
      >
        <SelectControl {...register('accountType')}>
          <option value="" selected disabled>
            -- Select an account type --
          </option>
          <option>School</option>
          <option>Other</option>
        </SelectControl>
      </FieldTemplate>

      {accountType === 'School' && (
        <>
          <FieldTemplate
            label="School Name"
            controlName="schoolName"
            status={errors.schoolName ? 'error' : undefined}
            feedback={errors.schoolName && errors.schoolName.message}
          >
            <TextControl {...register('schoolName')} />
          </FieldTemplate>

          <FieldTemplate
            label="Position"
            controlName="position"
            status={errors.position ? 'error' : undefined}
            feedback={errors.position && errors.position.message}
          >
            <TextControl {...register('position')} />
          </FieldTemplate>

          <FieldTemplate
            label="Postcode/Zip Code"
            controlName="postcode"
            status={errors.postcode ? 'error' : undefined}
            feedback={errors.postcode && errors.postcode.message}
          >
            <TextControl {...register('postcode')} />
          </FieldTemplate>
          {country === 'US' && (
            <>
              <FieldTemplate
                label="District"
                controlName="district"
                status={errors.district ? 'error' : undefined}
                feedback={errors.district && errors.district.message}
              >
                <TextControl {...register('district')} />
              </FieldTemplate>

              <FieldTemplate
                label="School Institution or Department"
                controlName="institution"
                status={errors.institution ? 'error' : undefined}
                feedback={errors.institution && errors.institution.message}
              >
                <TextControl {...register('institution')} />
              </FieldTemplate>
            </>
          )}
        </>
      )}

      {country === 'US' ? (
        <FieldTemplate
          label="State"
          controlName="state"
          status={errors.state ? 'error' : undefined}
          feedback={errors.state && errors.state.message}
        >
          <TextControl {...register('state')} />
        </FieldTemplate>
      ) : (
        <FieldTemplate
          label="Country"
          controlName="country"
          status={errors.country ? 'error' : undefined}
          feedback={errors.country && errors.country.message}
        >
          <TextControl {...register('country')} />
        </FieldTemplate>
      )}

      <FieldTemplate
        label="Phone Number"
        controlName="phoneNumber"
        status={errors.phoneNumber ? 'error' : undefined}
        feedback={errors.phoneNumber && errors.phoneNumber.message}
      >
        <TextControl {...register('phoneNumber')} />
      </FieldTemplate>

      <FieldTemplate
        controlName="existingCustomer"
        status={errors.existingCustomer ? 'error' : undefined}
        feedback={errors.existingCustomer && errors.existingCustomer.message}
      >
        <CheckControl type="checkbox" {...register('existingCustomer')}>
          Are you an existing customer?
        </CheckControl>
      </FieldTemplate>

      {existingCustomer && (
        <FieldTemplate
          label="Nessy Secret Word"
          controlName="nessySecretWord"
          status={errors.nessySecretWord ? 'error' : undefined}
          feedback={errors.nessySecretWord && errors.nessySecretWord.message}
        >
          <TextControl {...register('nessySecretWord')} />
        </FieldTemplate>
      )}

      <FieldTemplate
        label="Which Nessy programs are you interested in?"
        controlName="program"
        status={errors.program ? 'error' : undefined}
        feedback={errors.program && errors.program.message}
      >
        <Stack gap="small">
          {programs.map((program, i) => (
            <CheckControl
              {...register('program')}
              type="checkbox"
              value={program}
              key={`program-${i}`}
            >
              {program === 'Other'
                ? 'Other – Please tell us which program you would like to know more about'
                : program}
            </CheckControl>
          ))}
        </Stack>
      </FieldTemplate>

      {program === 'Other' && (
        <FieldTemplate
          label="Program"
          controlName="otherProgram"
          status={errors.otherProgram ? 'error' : undefined}
          feedback={errors.otherProgram && errors.otherProgram.message}
        >
          <TextControl {...register('otherProgram')} />
        </FieldTemplate>
      )}

      <FieldTemplate
        label="How many licenses would you like to purchase?"
        controlName="licenseQuantity"
        status={errors.licenseQuantity ? 'error' : undefined}
        feedback={errors.licenseQuantity && errors.licenseQuantity.message}
      >
        <TextControl {...register('licenseQuantity')} />
      </FieldTemplate>

      <FieldTemplate
        label="Comments"
        controlName="comments"
        status={errors.comments ? 'error' : undefined}
        feedback={errors.comments && errors.comments.message}
      >
        <TextControl multiLine {...register('comments')} />
      </FieldTemplate>
    </>
  )
}

SchoolPagesSales.propTypes = {
  country: string.isRequired,
  errors: object.isRequired,
  watch: func.isRequired,
  register: func.isRequired
}

export default SchoolPagesSales
