import React, { Children, useEffect, useRef, useState } from 'react'
import { node, oneOfType, arrayOf } from 'prop-types'
import classNames from 'classnames'

import Flickity from 'react-flickity-component'

import baseStyles from './lib/Flickity.module.scss'
import styles from './Carousel.module.scss'

import PaginationSummary from '@/component/Primitive/PaginationSummary'

const Carousel = ({ children }) => {
  const [currentIndex, updateCurrentIndex] = useState(0)

  const flickity = useRef()

  const handleNext = () => {
    flickity.current.next()
  }

  const handlePrevious = () => {
    flickity.current.previous()
  }

  const handleUpdate = () => {
    updateCurrentIndex(flickity.current.selectedIndex)
  }

  const flickityOptions = {
    cellAlign: 'left',
    prevNextButtons: false,
    pageDots: false,
    contain: true
  }

  const totalSlides =
    flickity?.current?.slides?.length || Children.count(children)

  useEffect(() => {
    flickity.current.on('ready', handleUpdate)
    flickity.current.on('change', handleUpdate)

    return () => {
      flickity.current.destroy()
    }
  }, [])

  return (
    <div className={classNames(baseStyles.Carousel, styles.Carousel)}>
      <Flickity
        static
        flickityRef={(c) => (flickity.current = c)}
        options={flickityOptions}
      >
        {children}
      </Flickity>
      <div className={styles.CarouselPaginationSummary}>
        <PaginationSummary
          onNext={handleNext}
          onPrevious={handlePrevious}
          currentSlide={currentIndex + 1}
          totalSlides={totalSlides}
          showCount
        />
      </div>
    </div>
  )
}

Carousel.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
}

export default Carousel
