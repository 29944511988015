import React from 'react'
import { object } from 'prop-types'

import SocialShare from '@/component/Primitive/SocialShare'

const Share = ({ pageData }) => {
  const url = encodeURI(pageData?.url)

  const links = [
    {
      type: 'facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${url}`
    },
    { type: 'twitter', url: `http://www.twitter.com/share?url=${url}` }
    // { type: 'reddit', url: `http://www.reddit.com/submit?url=${url}` }
  ]

  return <SocialShare links={links} />
}

Share.propTypes = {
  pageData: object
}

export default Share
