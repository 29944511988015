import schemata from 'schemata'
import { createValidator, booleanToCallback } from '@clocklimited/validity'
import required from '@clocklimited/validity-required'
import validateIfPropertyEquals from '@clocklimited/validity-validate-if-property-equals'

import createTeacherSchema from './teacher-schema'
import validateLength from 'validity-length'

const termsValidator = createValidator(
  booleanToCallback((value) => value === true)
)('You must agree to the Terms and Conditions & Privacy Policy')

const atLeastOneProductValidator = createValidator(
  booleanToCallback((value) => value && value.length >= 1)
)('You must select at least one product')

const createSchema = (formType, country) => {
  const ifSchoolAccount = (validator) =>
    validateIfPropertyEquals('accountType', 'School', validator)
  const ifHomeAccount = (validator) =>
    validateIfPropertyEquals('accountType', 'Home', validator)
  const ifOtherAccount = (validator) =>
    validateIfPropertyEquals('accountType', 'Other', validator)
  const ifUs = (validator) =>
    validateIfPropertyEquals('formCountry', 'US', validator)
  const ifUk = (validator) =>
    validateIfPropertyEquals('formCountry', 'UK', validator)

  return schemata({
    name: 'ContactUs',
    properties: {
      _id: {
        type: String
      },

      formType: {
        type: String,
        defaultValue: () => formType
      },
      formCountry: {
        type: String,
        defaultValue: () => country
      },

      // All forms have these
      termsAgreed: {
        type: Boolean,
        validators: {
          'Help & Support': [required, termsValidator],
          'School Pages Sales': [required, termsValidator],
          'Register Interest': [required, termsValidator],
          'Contact Us': [required, termsValidator],
          'Hairy Reading - Free Trial': [required, termsValidator],
          'Training RSVP': [required, termsValidator],
          'Dyslexia Aware Badge': [required, termsValidator]
        }
      },
      firstName: {
        type: String,
        validators: {
          'Help & Support': [required],
          'School Pages Sales': [required],
          'Register Interest': [required],
          'Contact Us': [required],
          'Hairy Reading - Free Trial': [required],
          'Training RSVP': [required],
          'Dyslexia Aware Badge': [required]
        }
      },
      lastName: {
        type: String,
        validators: {
          'Help & Support': [required],
          'School Pages Sales': [required],
          'Register Interest': [required],
          'Contact Us': [required],
          'Hairy Reading - Free Trial': [required],
          'Training RSVP': [required],
          'Dyslexia Aware Badge': [required]
        }
      },
      email: {
        type: String,
        validators: {
          'Help & Support': [required],
          'School Pages Sales': [required],
          'Register Interest': [required],
          'Contact Us': [required],
          'Hairy Reading - Free Trial': [required],
          'Training RSVP': [required],
          'Dyslexia Aware Badge': []
        }
      },

      // Help & Support
      accountType: {
        type: String,
        validators: {
          'Help & Support': [required],
          'School Pages Sales': [required],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': []
        }
      },

      schoolName: {
        type: String,
        validators: {
          'Help & Support': [ifSchoolAccount(required)],
          'School Pages Sales': [ifSchoolAccount(required)],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [required],
          'Dyslexia Aware Badge': [required]
        }
      },

      postcode: {
        name: 'Postcode / ZIP code',
        type: String,
        validators: {
          'Help & Support': [ifSchoolAccount(required)],
          'School Pages Sales': [ifSchoolAccount(required)],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [required],
          'Dyslexia Aware Badge': [ifUs(required)]
        }
      },

      country: {
        type: String,
        validators: {
          'Help & Support': [ifHomeAccount(required), ifOtherAccount(required)],
          'School Pages Sales': [
            ifUk(ifHomeAccount(required)),
            ifUk(ifOtherAccount(required))
          ],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [ifUk(required)],
          'Dyslexia Aware Badge': [required]
        }
      },

      phoneNumber: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': []
        }
      },

      existingCustomer: {
        type: Boolean,
        validators: [],
        defaultValue: false
      },

      nessySecretWord: {
        type: String,
        validators: {
          'Help & Support': [
            validateIfPropertyEquals('existingCustomer', true, required)
          ],
          'School Pages Sales': [
            validateIfPropertyEquals('existingCustomer', true, required)
          ],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [
            validateIfPropertyEquals('existingCustomer', true, required)
          ],
          'Dyslexia Aware Badge': [required]
        }
      },

      program: {
        type: Array,
        validators: {
          'Help & Support': [required],
          'School Pages Sales': [required],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': []
        }
      },

      otherProgram: {
        type: String,
        validators: {
          'Help & Support': [
            validateIfPropertyEquals('program', 'Other', required)
          ],
          'School Pages Sales': [
            validateIfPropertyEquals('program', 'Other', required)
          ],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': []
        }
      },

      problemDescription: {
        type: String,
        validators: {
          'Help & Support': [required],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': []
        }
      },

      device: {
        type: String,
        validators: []
      },

      browser: {
        type: String,
        validators: []
      },

      // School Pages Sales

      // @includes: accountType

      // @includes: schoolName

      position: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [ifSchoolAccount(required)],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [ifUs(required)],
          'Dyslexia Aware Badge': []
        }
      },

      // @includes: postcode

      district: {
        name: 'District',
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [ifSchoolAccount(ifUs(required))],
          'Register Interest': [],
          'Contact Us': []
        }
      },

      institution: {
        name: 'School Institution or Department',
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [ifSchoolAccount(ifUs(required))],
          'Register Interest': [],
          'Contact Us': []
        }
      },

      state: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [
            ifHomeAccount(ifUs(required)),
            ifOtherAccount(ifUs(required))
          ],
          'Register Interest': [],
          'Contact Us': []
        }
      },

      // @includes: existingCustomer

      // @includes: nessySecretWord

      licenseQuantity: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [required],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [required]
        }
      },

      // Register Interest

      interest: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [required],
          'Contact Us': []
        }
      },

      comments: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [
            validateIfPropertyEquals('interest', 'General Enquiry', required)
          ],
          'Contact Us': [],
          'Dyslexia Aware Badge': [required]
        }
      },

      // Contact Us

      enquiry: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [required]
        }
      },

      // Hairy Reading - Free Trial

      isAccountAdministrator: {
        type: Boolean,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [required]
        }
      },

      adminFirstName: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [
            validateIfPropertyEquals('isAccountAdministrator', false, required)
          ]
        }
      },

      adminLastName: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [
            validateIfPropertyEquals('isAccountAdministrator', false, required)
          ]
        }
      },

      adminPosition: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [
            validateIfPropertyEquals('isAccountAdministrator', false, required)
          ]
        }
      },

      adminEmail: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [
            validateIfPropertyEquals('isAccountAdministrator', false, required)
          ],
          'Dyslexia Aware Badge': [required]
        }
      },

      preferedLanguage: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [required],
          'Training RSVP': []
        }
      },

      // Training RSVP

      attendeeCount: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': [required]
        }
      },

      accountIdOrSecretWord: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': [required]
        }
      },

      trainingPrograms: {
        type: Array,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': [required, atLeastOneProductValidator]
        }
      },

      specificQueries: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': []
        }
      },

      preferredDate: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': [required]
        }
      },

      wantsQuote: {
        type: Boolean,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': []
        }
      },

      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      },

      // Dyslexia Aware Badge

      schoolAddress: {
        type: String,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': [],
          'Dyslexia Aware Badge': [required]
        }
      },

      noOfPupils: {
        type: Number,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': [],
          'Dyslexia Aware Badge': [required]
        }
      },

      noOfStaff: {
        type: Number,
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': [],
          'Dyslexia Aware Badge': [required]
        }
      },

      teachers: {
        type: schemata.Array(createTeacherSchema()),
        validators: {
          'Help & Support': [],
          'School Pages Sales': [],
          'Register Interest': [],
          'Contact Us': [],
          'Hairy Reading - Free Trial': [],
          'Training RSVP': [],
          'Dyslexia Aware Badge': [validateLength(1)]
        },
        defaultValue: () => []
      }
    }
  })
}

export default createSchema
