import React from 'react'
import { func, array, object } from 'prop-types'
import widgetFactory from '../../Widgets'

import WidgetWrapper from '@/component/Primitive/WidgetWrapper'

const WidgetArea = ({ widgets, __url, article, section, pageData }) => {
  if (!widgets || widgets.length === 0) return null
  return widgets?.map((widget) => {
    if (!widget) return null
    const Component = widgetFactory[widget.type]
    // if (!Component) console.log('Widget type not found:', widget.type)
    if (!Component) return null
    const widgetProps = {
      ...widget,
      copyColor: widget.copyColor && `#${widget.copyColor}`,
      titleColor: widget.titleColor && `#${widget.titleColor}`
    }
    const componentProps = { ...widgetProps, article, section, pageData, __url }

    const wrapperExemptions = [
      'articleLayoutHeader',
      'articleLayoutBody',
      'banner',
      'relatedWidgets'
    ]

    if (wrapperExemptions.includes(widget.type)) {
      return <Component key={widget.id} {...componentProps} />
    }

    return (
      <WidgetWrapper
        key={widget.id}
        displayOptions={widget.displayOptions}
        essential={widget.essential}
      >
        <Component {...componentProps} />
      </WidgetWrapper>
    )
  })
}

WidgetArea.propTypes = {
  widgets: array,
  __url: func.isRequired,
  article: object,
  section: object,
  pageData: object
}

export default WidgetArea
