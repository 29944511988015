import React, { forwardRef } from 'react'
import { bool } from 'prop-types'

import CustomSelectControl from './CustomSelectControl'
import NativeSelectControl from './NativeSelectControl'

const SelectControl = forwardRef(({ native, ...controlProps }, ref) => {
  const SelectControlType = native ? NativeSelectControl : CustomSelectControl

  return <SelectControlType ref={ref} {...controlProps} />
})

SelectControl.displayName = 'SelectControl'

SelectControl.propTypes = {
  native: bool
}

export default SelectControl
