import React from 'react'
import { bool, object, oneOf, string } from 'prop-types'

import Container from '@/component/Primitive/Container'
import VideoPreview from '@/component/Primitive/VideoPreview'
import VideoProvider from '@/component/Primitive/VideoProvider'

const VideoWidget = ({
  attributionName,
  attributionCompany,
  alternateLayout,
  images,
  provider,
  title,
  subtitle,
  videoId,
  titleColor,
  copyColor
}) => {
  const getImage = (size) => images?.[`preview_16x9_${size}`]?.[0]

  const srcSet = [
    {
      width: 0,
      src: getImage(400).url
    },
    {
      width: 400,
      src: getImage(800).url
    },
    {
      width: 800,
      src: getImage(1048).url
    }
  ]

  return (
    <Container center gutter size="large">
      <VideoPreview
        alt=""
        srcSet={srcSet}
        title={title}
        description={subtitle}
        attributionPrimary={attributionName}
        attributionSecondary={attributionCompany}
        alternateLayout={alternateLayout}
        titleColor={titleColor}
        copyColor={copyColor}
      >
        <VideoProvider provider={provider} videoId={videoId} />
      </VideoPreview>
    </Container>
  )
}

VideoWidget.propTypes = {
  attributionName: string,
  attributionCompany: string,
  images: object,
  provider: oneOf(['youtube', 'vimeo']),
  title: string,
  alternateLayout: bool,
  subtitle: string,
  videoId: string,
  titleColor: string,
  copyColor: string
}

export default VideoWidget
