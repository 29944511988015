import imageProperties from '../lib/image-properties'

const createQuery = () => `
... on ProductGridWidget {
  id
  showProductDescriptions
  showProductPricingDetails
  showProductTitlesAndVersionTypes
  products {
    name
    slug
    price
    currencyISO
    description
    versionType
    pricingDetails {
        price
        billingType
        previousPrice
    }
    showPricing
    showDescription
    showTitleAndVersionType
    instance {
        slug
    }
    images {
        hero_1x1_200 {
            ${imageProperties}
        }
        hero_1x1_500 {
            ${imageProperties}
        }
        hero_2x1_600 {
            ${imageProperties}
        }
        hero_2x1_800 {
            ${imageProperties}
        }
        hero_2x1_1000 {
            ${imageProperties}
        }
        hero_2x1_1280 {
            ${imageProperties}
        }
        thumbnail_1x1_300 {
            ${imageProperties}
        }
    }
  }
}
`
export default createQuery
