import React from 'react'
import { bool, func, number } from 'prop-types'
import { useTranslation } from 'react-i18next'

import IconButton from '@/component/Primitive/IconButton'

import styles from './PaginationSummary.module.scss'

const PaginationSummary = ({
  onNext,
  onPrevious,
  currentSlide,
  totalSlides,
  showCount
}) => {
  const { t } = useTranslation()

  const previousDisabled = currentSlide <= 1
  const nextDisabled = currentSlide >= totalSlides

  return (
    <div className={styles.PaginationSummary}>
      <IconButton
        className={styles.PaginationSummaryButton}
        {...(previousDisabled && { disabled: true })}
        {...(!previousDisabled && { onClick: onPrevious })}
        a11yText={
          previousDisabled ? t('PaginationNoPrevious') : t('PaginationPrevious')
        }
        iconWidth={32}
        icon="navigate-previous"
        small
        increaseHitArea
      />
      {showCount && (
        <div className={styles.PaginationSummaryText}>
          {t('PaginationCount', { current: currentSlide, total: totalSlides })}
        </div>
      )}
      <IconButton
        className={styles.PaginationSummaryButton}
        {...(nextDisabled && { disabled: true })}
        {...(!nextDisabled && { onClick: onNext })}
        a11yText={nextDisabled ? t('PaginationNoNext') : t('PaginationNext')}
        iconWidth={32}
        icon="navigate-next"
        small
        increaseHitArea
      />
    </div>
  )
}

PaginationSummary.defaultProps = {
  onNext: () => {},
  onPrevious: () => {},
  currentSlide: 1,
  totalSlides: 5,
  showCount: true
}

PaginationSummary.propTypes = {
  onNext: func.isRequired,
  onPrevious: func.isRequired,
  currentSlide: number,
  totalSlides: number.isRequired,
  showCount: bool
}

export default PaginationSummary
