const createQuery = () => `
  ... on FormBuilderWidget {
    formBuilder {
        _id
        title
        thankyouMessage
        questions {
            question
            required
            inputType
            wordLimit
            width
            responses {
                response
            }
        }
        email
    }
  }
`
export default createQuery
