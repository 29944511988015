import React, { useContext, forwardRef } from 'react'
import { bool, oneOf } from 'prop-types'
import classNames from 'classnames'

import { StatusContext } from '@/component/Context/StatusContext'

import styles from './CustomSelectControl.module.scss'

import Icon from '../../Icon'
import NativeSelectControl from '../NativeSelectControl'

const CustomSelectControl = forwardRef(
  ({ disabled, multiple, status, ...other }, ref) => {
    const contextStatus = useContext(StatusContext)
    const derivedStatus = status || contextStatus

    return (
      <span
        className={classNames(
          styles.CustomSelectControl,
          multiple && styles.multiple,
          derivedStatus && styles[derivedStatus],
          disabled && styles.disabled
        )}
      >
        {!multiple && (
          <div className={styles.CustomSelectControlIndicator} aria-hidden>
            <Icon type="expand-more" a11yText="" />
          </div>
        )}
        <NativeSelectControl
          disabled={disabled}
          multiple={multiple}
          status={derivedStatus}
          ref={ref}
          {...other}
        />
      </span>
    )
  }
)

CustomSelectControl.displayName = 'CustomSelectControl'

CustomSelectControl.propTypes = {
  disabled: bool,
  multiple: bool,
  status: oneOf(['error', 'notice', 'success', 'warning'])
}

export default CustomSelectControl
