import React, { useContext } from 'react'
import { string, object } from 'prop-types'

import { RowContext } from '@/component/Context/RowContext'

import Container from '@/component/Primitive/Container'
import PromoDouble from '@/component/Composition/PromoDouble'
import PromoSquare from '@/component/Composition/PromoSquare'
import Prose from '@/component/Primitive/Prose'
import Stack from '@/component/Primitive/Stack'
import TextAlign from '@/component/Primitive/TextAlign'

const PromoDoubleSquaresWidget = ({
  ctaLink,
  ctaLinkSecondary,
  title,
  titleSecondary,
  text,
  images,
  copyColor
}) => {
  const contextRowType = useContext(RowContext)
  const getImage = (context, size) => images?.[`${context}_1x1_${size}`]?.[0]
  const primarySrcSet = [
    {
      width: 600,
      src: getImage('primary', 600)?.url
    },
    {
      width: 800,
      src: getImage('primary', 800)?.url
    },
    {
      width: 1000,
      src: getImage('primary', 1000)?.url
    }
  ]
  const secondarySrcSet = [
    {
      width: 600,
      src: getImage('secondary', 600)?.url
    },
    {
      width: 800,
      src: getImage('secondary', 800)?.url
    },
    {
      width: 1000,
      src: getImage('secondary', 1000)?.url
    }
  ]

  const sizes = [
    '(min-width: 1310px) 576px',
    '(min-width: 960px) calc(50% - 80px)',
    '(min-width: 600px) calc(50% - 48px)',
    'calc(100vw - 64px)'
  ]

  return (
    <Container center size="wide" gutter={contextRowType === 'panelled'}>
      <Stack>
        <PromoDouble
          primary={
            <PromoSquare
              title={title}
              ctaLink={ctaLink}
              src={primarySrcSet[0].src}
              srcSet={primarySrcSet}
              sizes={sizes}
            />
          }
          secondary={
            <PromoSquare
              title={titleSecondary}
              ctaLink={ctaLinkSecondary}
              src={secondarySrcSet[0].src}
              srcSet={secondarySrcSet}
              sizes={sizes}
              hoverOffset={1}
            />
          }
        />
        <Container center size="medium">
          <TextAlign center>
            <Prose dangerousHtml={text} color={copyColor} />
          </TextAlign>
        </Container>
      </Stack>
    </Container>
  )
}

PromoDoubleSquaresWidget.propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  ctaLink: string.isRequired,
  titleSecondary: string.isRequired,
  ctaLinkSecondary: string.isRequired,
  images: object.isRequired,
  copyColor: string
}

export default PromoDoubleSquaresWidget
