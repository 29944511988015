import React from 'react'
import { object, func } from 'prop-types'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import TextControl from '@/component/Primitive/TextControl'
import CheckControl from '@/component/Primitive/CheckControl'
import Fieldset from '@/component/Primitive/Fieldset'
import Stack from '@/component/Primitive/Stack'

const TrainingRsvp = ({ errors, register }) => {
  const trainingPrograms = [
    'Nessy Reading and Spelling',
    'Hairy Reading',
    'Writing Beach',
    'Nessy Fingers',
    'Dyslexia Quest'
  ]
  return (
    <>
      <Fieldset row>
        <FieldTemplate
          label="First Name"
          controlName="firstName"
          status={errors.firstName ? 'error' : undefined}
          feedback={errors.firstName && errors.firstName.message}
        >
          <TextControl {...register('firstName')} />
        </FieldTemplate>

        <FieldTemplate
          label="Last Name"
          controlName="lastName"
          status={errors.lastName ? 'error' : undefined}
          feedback={errors.lastName && errors.lastName.message}
        >
          <TextControl {...register('lastName')} />
        </FieldTemplate>
      </Fieldset>

      <FieldTemplate
        label="Email address to receive your training link (you will receive this within 24 hours of the training)"
        controlName="email"
        status={errors.email ? 'error' : undefined}
        feedback={errors.email && errors.email.message}
      >
        <TextControl {...register('email')} />
      </FieldTemplate>

      <FieldTemplate
        label="How many people will be attending and watching the training from your device? (You may each register individually and participate from your own devices, or you may gather in one area and share a device)"
        controlName="attendeeCount"
        status={errors.attendeeCount ? 'error' : undefined}
        feedback={errors.attendeeCount && errors.attendeeCount.message}
      >
        <TextControl {...register('attendeeCount')} />
      </FieldTemplate>

      <FieldTemplate
        label="Account ID number (or Secret Word)"
        controlName="accountIdOrSecretWord"
        status={errors.accountIdOrSecretWord ? 'error' : undefined}
        feedback={
          errors.accountIdOrSecretWord && errors.accountIdOrSecretWord.message
        }
      >
        <TextControl {...register('accountIdOrSecretWord')} />
      </FieldTemplate>

      <FieldTemplate
        label="Which program(s) would you like to receive training for? (you may select more than one)"
        controlName="trainingPrograms"
        status={errors.trainingPrograms ? 'error' : undefined}
        feedback={errors.trainingPrograms && errors.trainingPrograms.message}
      >
        <Stack gap="small">
          {trainingPrograms.map((program, i) => (
            <CheckControl
              {...register('trainingPrograms')}
              type="checkbox"
              value={program}
              key={`trainingPrograms-${i}`}
            >
              {program}
            </CheckControl>
          ))}
        </Stack>
      </FieldTemplate>

      <FieldTemplate
        label="Are there any specific queries or topics you would like covered? List and explain in the comments box below"
        controlName="specificQueries"
        status={errors.specificQueries ? 'error' : undefined}
        feedback={errors.specificQueries && errors.specificQueries.message}
      >
        <TextControl multiLine {...register('specificQueries')} />
      </FieldTemplate>

      <FieldTemplate
        label="Please let us know your preferred date, time, and time zone from the list at the top of the page"
        controlName="preferredDate"
        status={errors.preferredDate ? 'error' : undefined}
        feedback={errors.preferredDate && errors.preferredDate.message}
      >
        <TextControl multiLine {...register('preferredDate')} />
      </FieldTemplate>

      <FieldTemplate
        controlName="wantsQuote"
        status={errors.wantsQuote ? 'error' : undefined}
        feedback={errors.wantsQuote && errors.wantsQuote.message}
      >
        <CheckControl type="checkbox" {...register('wantsQuote')}>
          If you would like customized, private training for your school or
          district, please check the box and we will be happy to provide you
          with a quote.
        </CheckControl>
      </FieldTemplate>
    </>
  )
}

TrainingRsvp.propTypes = {
  errors: object.isRequired,
  register: func.isRequired
}

export default TrainingRsvp
