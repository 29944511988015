import React from 'react'
import { array, bool, string } from 'prop-types'

import styles from './Card.module.scss'

import ButtonStandard from '../ButtonStandard'
import OverlayLink from '../OverlayLink'
import ResponsiveImage from '../ResponsiveImage'
import ResponsiveMedia from '../ResponsiveMedia'
import Type from '../Type'

const generateUniqueId = (string) => {
  const trimLength = 20
  const safeString = string.replace(/\W/g, '')
  return `Card-${safeString.substring(
    safeString.length - trimLength,
    trimLength
  )}`
}

const Card = ({
  ctaText,
  description,
  link,
  title,
  src,
  srcSet,
  sizes,
  titleColor,
  copyColor,
  to,
  square
}) => {
  const id = ctaText ? generateUniqueId(link) : null
  return (
    <li className={styles.Card}>
      <div className={styles.CardImage}>
        <div className={styles.CardImageInner}>
          <ResponsiveMedia ratio={square ? 1 : 9 / 16}>
            <ResponsiveImage
              alt=""
              src={src}
              srcSet={srcSet}
              sizes={sizes}
              width={310}
              height={square ? 310 : 174}
            />
          </ResponsiveMedia>
        </div>
      </div>
      <Type as="h3" size="heading5" color={titleColor || 'purple'}>
        <div className={styles.CardHeading}>
          {to ? (
            <OverlayLink to={to} {...(ctaText && { 'aria-describedby': id })}>
              {title}
            </OverlayLink>
          ) : (
            <OverlayLink
              href={link}
              {...(ctaText && { 'aria-describedby': id })}
            >
              {title}
            </OverlayLink>
          )}
        </div>
      </Type>
      {description && (
        <div className={styles.CardContent}>
          <Type size="small" color={copyColor}>
            {description}
          </Type>
        </div>
      )}
      {ctaText && (
        <div className={styles.CardAction}>
          <ButtonStandard aria-hidden="true" href={link} id={id}>
            {ctaText}
          </ButtonStandard>
        </div>
      )}
    </li>
  )
}

Card.propTypes = {
  ctaText: string,
  description: string,
  link: string,
  to: string,
  title: string.isRequired,
  src: string,
  srcSet: array, // TODO: shape
  sizes: array, // TODO: shape,
  titleColor: string,
  copyColor: string,
  square: bool
}

export default Card
