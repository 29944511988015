import React from 'react'
import { object, string } from 'prop-types'

import TextHero from '@/component/Composition/TextHero'
import SpacingReverser from '@/component/Primitive/SpacingReverser'

const TextHeroWidget = ({
  images,
  title,
  description,
  details,
  primaryText,
  primaryLink,
  secondaryText,
  secondaryLink,
  titleColor,
  copyColor
}) => {
  const getImage = (size) => images?.[`hero_2x1_${size}`]?.[0]

  const srcSet = [
    {
      width: 0,
      src: getImage(600)?.url
    },
    {
      width: 800,
      src: getImage(800)?.url
    },
    {
      width: 1000,
      src: getImage(1000)?.url
    },
    {
      width: 1280,
      src: getImage(1280)?.url
    },
    {
      width: 1600,
      src: getImage(1600)?.url
    },
    {
      width: 2000,
      src: getImage(2000)?.url
    },
    {
      width: 2500,
      src: getImage(2500)?.url
    }
  ]
  return (
    <SpacingReverser main>
      <TextHero
        srcSet={srcSet}
        title={title}
        description={description}
        details={details}
        ctaPrimaryText={primaryText}
        ctaPrimaryLink={primaryLink}
        ctaSecondaryText={secondaryText}
        ctaSecondaryLink={secondaryLink}
        titleColor={titleColor}
        copyColor={copyColor}
      />
    </SpacingReverser>
  )
}

TextHeroWidget.propTypes = {
  images: object,
  title: string.isRequired,
  description: string.isRequired,
  details: string.isRequired,
  primaryText: string,
  primaryLink: string,
  secondaryText: string,
  secondaryLink: string,
  titleColor: string,
  copyColor: string
}

export default TextHeroWidget
