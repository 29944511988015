import React, { useContext, forwardRef } from 'react'
import {
  bool,
  func,
  number,
  oneOf,
  oneOfType,
  string,
  object
} from 'prop-types'
import classNames from 'classnames'

import { StatusContext } from '@/component/Context/StatusContext'

import styles from './TextControl.module.scss'

const TextControl = forwardRef(
  (
    {
      autoComplete,
      className,
      controlRef,
      defaultValue,
      disabled,
      form,
      id,
      inputMode,
      max,
      min,
      maxLength,
      minLength,
      multiLine,
      name,
      onBlur,
      onChange,
      onFocus,
      pattern,
      placeholder,
      readOnly,
      required,
      rows,
      size,
      status,
      tabIndex,
      type,
      value
    },
    ref
  ) => {
    const contextStatus = useContext(StatusContext)
    const derivedStatus = status || contextStatus
    const TextControlEl = multiLine ? 'textarea' : 'input'
    const componentClassName = classNames(
      styles.TextControl,
      multiLine && styles.multiLine,
      derivedStatus && styles[derivedStatus],
      disabled && styles.disabled,
      className
    )

    return (
      <TextControlEl
        aria-invalid={status === 'error' || undefined}
        aria-required={required}
        autoComplete={autoComplete}
        // autoFocus is intentionally omitted for a11y reasons:
        // https://w3c.github.io/html/sec-forms.html#autofocusing-a-form-control-the-autofocus-attribute
        className={componentClassName}
        defaultValue={defaultValue}
        disabled={disabled}
        form={form}
        id={id || name}
        inputMode={inputMode}
        max={max}
        min={min}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        pattern={pattern}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={controlRef || ref}
        required={required}
        rows={multiLine ? rows : undefined}
        size={size}
        tabIndex={tabIndex}
        type={!multiLine ? type : undefined}
        value={value}
      />
    )
  }
)

TextControl.displayName = 'TextControl'

TextControl.defaultProps = {
  rows: 3,
  type: 'text'
}

TextControl.propTypes = {
  autoComplete: oneOfType([bool, string]),
  className: string,
  controlRef: oneOfType([func, object]),
  defaultValue: string,
  disabled: bool,
  form: string,
  id: string,
  inputMode: string,
  max: number,
  min: number,
  maxLength: number,
  minLength: number,
  multiLine: bool,
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  onFocus: func,
  pattern: string,
  placeholder: string,
  readOnly: bool,
  required: bool,
  rows: number,
  size: number,
  status: oneOf(['none', 'error', 'notice', 'success', 'warning']),
  tabIndex: number,
  type: string,
  value: string
}

export default TextControl
