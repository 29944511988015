import React from 'react'
import { bool, string, object } from 'prop-types'

import Container from '@/component/Primitive/Container'
import SplitSummary from '@/component/Primitive/SplitSummary'

import changeFileExtension from '@/lib/filename-extension-modifier'

const ImageWithTextWidget = ({
  crop,
  title,
  a11yTitle,
  text,
  reverse,
  images,
  url,
  titleColor,
  copyColor
}) => {
  const ratio = crop === '16:9' ? '16x9' : '4x3'

  const getImage = (size) => images?.[`main_${ratio}_${size}`]?.[0]

  const srcSet = [
    {
      width: 400,
      src: changeFileExtension(getImage(400)?.url, 'webp')
    },
    {
      width: 546,
      src: changeFileExtension(getImage(546)?.url, 'webp')
    },
    {
      width: 800,
      src: changeFileExtension(getImage(800)?.url, 'webp')
    },
    {
      width: 1092,
      src: changeFileExtension(getImage(1092)?.url, 'webp')
    }
  ]

  const image = {
    width: 546,
    height: crop === '16:9' ? 307 : 410,
    alt: a11yTitle,
    src: getImage(546)?.url,
    srcSet
  }

  return (
    <Container center gutter size="large">
      <SplitSummary
        title={title}
        description={text}
        reverse={reverse}
        image={image}
        url={url}
        titleColor={titleColor}
        copyColor={copyColor}
      />
    </Container>
  )
}

ImageWithTextWidget.propTypes = {
  crop: string.isRequired,
  title: string,
  a11yTitle: string,
  text: string.isRequired,
  reverse: bool,
  images: object,
  url: string,
  titleColor: string,
  copyColor: string
}

export default ImageWithTextWidget
