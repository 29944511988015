import React from 'react'
import { object, func } from 'prop-types'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import TextControl from '@/component/Primitive/TextControl'
import CheckControl from '@/component/Primitive/CheckControl'
import SelectControl from '@/component/Primitive/SelectControl'
import Fieldset from '@/component/Primitive/Fieldset'

const RegisterInterest = ({ errors, register }) => (
  <>
    <Fieldset row>
      <FieldTemplate
        label="First Name"
        controlName="firstName"
        status={errors.firstName ? 'error' : undefined}
        feedback={errors.firstName && errors.firstName.message}
      >
        <TextControl {...register('firstName')} />
      </FieldTemplate>

      <FieldTemplate
        label="Last Name"
        controlName="lastName"
        status={errors.lastName ? 'error' : undefined}
        feedback={errors.lastName && errors.lastName.message}
      >
        <TextControl {...register('lastName')} />
      </FieldTemplate>
    </Fieldset>

    <FieldTemplate
      label="Email"
      controlName="email"
      status={errors.email ? 'error' : undefined}
      feedback={errors.email && errors.email.message}
    >
      <TextControl {...register('email')} />
    </FieldTemplate>

    <FieldTemplate
      label="Existing Customer"
      controlName="existingCustomer"
      status={errors.existingCustomer ? 'error' : undefined}
      feedback={errors.existingCustomer && errors.existingCustomer.message}
    >
      <CheckControl type="checkbox" {...register('existingCustomer')}>
        Are you an existing customer?
      </CheckControl>
    </FieldTemplate>

    <FieldTemplate
      label="Please tell us what you are registering your interest in:"
      controlName="interest"
      status={errors.interest ? 'error' : undefined}
      feedback={errors.interest && errors.interest.message}
    >
      <SelectControl {...register('interest')}>
        <option value="">-- Select an interest --</option>
        <option>
          I would like to be added to the mailing list to receive Nessy News and
          updates
        </option>
        <option>
          I would like to be added to the mailing list for the release of a new
          program or product (please specify in comments box below)
        </option>
        <option value="General Enquiry">
          I have a general marketing enquiry (please specify in comments box
          below)
        </option>
      </SelectControl>
    </FieldTemplate>

    <FieldTemplate
      label="Comments"
      controlName="comments"
      status={errors.comments ? 'error' : undefined}
      feedback={errors.comments && errors.comments.message}
    >
      <TextControl multiLine {...register('comments')} />
    </FieldTemplate>
  </>
)

RegisterInterest.propTypes = {
  errors: object.isRequired,
  register: func.isRequired
}

export default RegisterInterest
