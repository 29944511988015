import React from 'react'
import PropTypes from 'prop-types'

import Stack from '@/component/Primitive/Stack'
import Container from '@/component/Primitive/Container'
import ProductPromo from '@/component/Primitive/ProductPromo'

import generateLink from './lib/link-generator'

const ProductPromoWidget = ({
  primaryCtaText,
  primaryCtaLink,
  secondaryCtaText,
  secondaryCtaLink,
  product: _product,
  titleColor,
  copyColor
}) => {
  const product = _product?.[0]

  if (!product) return null

  const link = generateLink(product)
  const imageUrl = product?.images?.thumbnail_1x1_352?.[0]?.url
  const getImage = (size) => product.images?.[`thumbnail_1x1_${size}`]?.[0]?.url

  const srcSet = [
    { width: 352, src: getImage(352) },
    { width: 448, src: getImage(448) },
    { width: 640, src: getImage(640) }
  ]

  return (
    <Container center gutter size="wide">
      <Container center gutter size="mediumLarge">
        <Stack gap="large">
          <Stack gap="small">
            <ProductPromo
              link={link}
              srcSet={srcSet}
              image={imageUrl}
              details={product.price}
              name={product.name}
              primaryCtaText={primaryCtaText}
              primaryCtaLink={primaryCtaLink}
              secondaryCtaText={secondaryCtaText}
              secondaryCtaLink={secondaryCtaLink}
              titleColor={titleColor}
              copyColor={copyColor}
            />
          </Stack>
        </Stack>
      </Container>
    </Container>
  )
}

ProductPromoWidget.propTypes = {
  product: PropTypes.array,
  primaryCtaText: PropTypes.string,
  primaryCtaLink: PropTypes.string,
  secondaryCtaText: PropTypes.string,
  secondaryCtaLink: PropTypes.string,
  titleColor: PropTypes.string,
  copyColor: PropTypes.string
}

export default ProductPromoWidget
