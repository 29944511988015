import React from 'react'
import { array, bool, func, number, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Tile.module.scss'

import ResponsiveMedia from '../ResponsiveMedia'
import ResponsiveImage from '../ResponsiveImage'
import SmartLink from '../SmartLink'

const Tile = ({
  active,
  alt,
  src,
  srcSet,
  sizes,
  to,
  href,
  onClick,
  size,
  hoverOffset
}) => {
  const clickable = to || href || onClick
  const TileEl = clickable ? SmartLink : 'div'

  return (
    <TileEl
      className={classNames(
        styles.Tile,
        active && styles.active,
        clickable && styles.clickable,
        size && styles[size],
        hoverOffset && styles[`hover-offset-${hoverOffset % 5}`]
      )}
      {...(href && { href })}
      {...(onClick && { onClick })}
      {...(to && { to })}
    >
      <div className={styles.TileInner}>
        <ResponsiveMedia ratio={1}>
          <ResponsiveImage
            width={128}
            height={128}
            alt={alt}
            src={src}
            srcSet={srcSet}
            sizes={sizes}
          />
        </ResponsiveMedia>
      </div>
    </TileEl>
  )
}

Tile.propTypes = {
  active: bool,
  src: string.isRequired,
  alt: string.isRequired,
  to: string,
  href: string,
  onClick: func,
  size: oneOf(['small', 'large', 'full']),
  hoverOffset: number,
  srcSet: array,
  sizes: array
}

export default Tile
