import React from 'react'
import { shape, object, string } from 'prop-types'

import TextHero from '@/component/Composition/TextHero'
import SpacingReverser from '@/component/Primitive/SpacingReverser'

const ProductHeroWidget = ({ pageData }) => {
  const { product, activeTab, fingerprint } = pageData

  if (product.hideHeroImage) return null

  const getImage = (size) => product.images?.[`hero_2x1_${size}`]?.[0]

  const srcSet = [
    {
      width: 0,
      src: getImage(600)?.url
    },
    {
      width: 800,
      src: getImage(800)?.url
    },
    {
      width: 1000,
      src: getImage(1000)?.url
    },
    {
      width: 1280,
      src: getImage(1280)?.url
    }
  ]

  const primaryCtaLink =
    product.primaryCtaLink &&
    `${product.primaryCtaLink}${
      fingerprint ? `?fingerprint=${fingerprint}` : ''
    }`

  if (activeTab !== 'overview') {
    return null
  }

  const { heroTitleColor, heroCopyColor } = product

  return (
    <SpacingReverser>
      <TextHero
        srcSet={srcSet}
        title={product.name}
        description={product.tagLine}
        details={product.price}
        ctaPrimaryLink={primaryCtaLink}
        ctaPrimaryText={product.primaryCtaText}
        titleColor={heroTitleColor && `#${heroTitleColor}`}
        copyColor={heroCopyColor && `#${heroCopyColor}`}
      />
    </SpacingReverser>
  )
}

ProductHeroWidget.propTypes = {
  pageData: shape({
    product: object.isRequired,
    activeTab: string.isRequired
  }).isRequired
}

export default ProductHeroWidget
