const createQuery = () => `
  ... on VideoWidget {
    images {
      preview_16x9_400 {
        url
        caption
        link
      }
      preview_16x9_800 {
        url
        caption
        link
      }
      preview_16x9_1048 {
        url
        caption
        link
      }
    }
    provider
    videoId
    title
    subtitle
    attributionName
    attributionCompany
    alternateLayout
  }
`
export default createQuery
