import imageProperties from '../lib/image-properties'

const createQuery = () => `
    ... on ProductPromoWidget {
        primaryCtaText
        primaryCtaLink
        secondaryCtaText
        secondaryCtaLink
        product {
            name
            slug
            price
            currencyISO
            instance {
                slug
            }
            images {
                hero_1x1_200 {
                    ${imageProperties}
                }
                hero_2x1_600 {
                    ${imageProperties}
                }
                hero_2x1_800 {
                    ${imageProperties}
                }
                hero_2x1_1000 {
                    ${imageProperties}
                }
                hero_2x1_1280 {
                    ${imageProperties}
                }
                thumbnail_1x1_300 {
                    ${imageProperties}
                }
                thumbnail_1x1_320 {
                    ${imageProperties}
                }
                thumbnail_1x1_352 {
                    ${imageProperties}
                }
                thumbnail_1x1_448 {
                    ${imageProperties}
                }
                thumbnail_1x1_640 {
                    ${imageProperties}
                }
            }
        }
    }
`

export default createQuery
