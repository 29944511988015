const createQuery = (subQuery, additionalQuery = '') => {
  const query = `query Resource($url:String!${additionalQuery}) {
    instance(url:$url) {
      _id
      slug
      languageCode
      customHtml
      translations {
        key
        value
      }
      translationOptions {
        name
        languageCode
        slug
        flagUrl
      }
      navItems {
        link
        name
        isShopLink
      }
      footerNavItems {
        link
        name
      }
      privacySection {
        fullUrlPath
      }
    }
    resource(url:$url) {
      __typename
      ...on Redirect {
        redirectUrl
      }
      ${subQuery}
    }
  }`
  return query
}
export default createQuery
