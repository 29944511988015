import React from 'react'
import { array } from 'prop-types'

import EmbellishedCardGrid from '@/component/Composition/EmbellishedCardGrid'

const RelatedContent = ({ articles }) => {
  const formattedArticles = articles?.map((article) => ({
    id: article._id,
    title: article.headline,
    description: article.sell,
    link: article.fullUrlPath,
    src: article.images?.thumbnail_16x9_310?.[0]?.url
  }))

  return (
    <EmbellishedCardGrid items={formattedArticles} title="Related Content" />
  )
}

RelatedContent.propTypes = {
  articles: array
}

export default RelatedContent
