import React from 'react'
import { node, oneOf, string } from 'prop-types'

import Container from '@/component/Primitive/Container'
import InlineVideo from '@/component/Composition/InlineVideo'

const InlineVideoWidget = ({ caption, provider, videoId }) => (
  <Container center gutter size="mediumLarge">
    <InlineVideo caption={caption} provider={provider} videoId={videoId} />
  </Container>
)

InlineVideoWidget.propTypes = {
  caption: node,
  provider: oneOf(['vimeo', 'youtube']),
  videoId: string
}

export default InlineVideoWidget
