import React from 'react'
import { string } from 'prop-types'

import Container from '@/component/Primitive/Container'
import Prose from '@/component/Primitive/Prose'

const TextWidget = ({ html, copyColor }) => (
  <Container center gutter size="mediumLarge">
    <Prose color={copyColor} dangerousHtml={html} />
  </Container>
)

TextWidget.propTypes = {
  html: string,
  copyColor: string
}

export default TextWidget
