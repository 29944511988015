import React from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Fieldset.module.scss'

const Fieldset = ({
  children,
  legend,
  legendcolor,
  row,
  trailingGap,
  fontSize
}) => {
  const FieldsetClassName = classNames(
    styles.Fieldset,
    row && styles.FieldsetRows
  )
  return (
    <fieldset className={FieldsetClassName}>
      <legend
        className={classNames(
          styles.FieldsetLegend,
          trailingGap && styles.trailingGap,
          fontSize && styles[`font-${fontSize}`]
        )}
        style={{ color: legendcolor }}
      >
        {legend}
      </legend>
      {children}
    </fieldset>
  )
}

Fieldset.propTypes = {
  children: node.isRequired,
  legend: node.isRequired,
  legendcolor: string,
  trailingGap: bool,
  fontSize: string,
  row: bool
}

export default Fieldset
