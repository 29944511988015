import React from 'react'
import { func, shape, object, string } from 'prop-types'

import Container from '@/component/Primitive/Container'
import WidgetArea from '@/component/Structure/WidgetArea'

const ProductContentWidget = ({ pageData, __url }) => {
  const { product, section, activeTab } = pageData
  return (
    <Container center gutter size="large">
      <WidgetArea
        widgets={product[activeTab]?.widgets}
        section={section}
        pageData={pageData}
        __url={__url}
      />
    </Container>
  )
}

ProductContentWidget.propTypes = {
  pageData: shape({
    product: object.isRequired,
    section: object.isRequired,
    activeTab: string.isRequired
  }).isRequired,
  __url: func.isRequired
}

export default ProductContentWidget
