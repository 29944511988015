import React from 'react'
import { oneOf, string } from 'prop-types'

import VimeoEmbed from '../VimeoEmbed'
import YouTubeEmbed from '../YouTubeEmbed'

const VideoProvider = ({ provider, videoId }) => {
  if (provider === 'vimeo') {
    return <VimeoEmbed videoId={videoId} color="#3bae70" />
  }
  if (provider === 'youtube') {
    return <YouTubeEmbed videoId={videoId} />
  }
  return null
}

VideoProvider.propTypes = {
  provider: oneOf(['vimeo', 'youtube']).isRequired,
  videoId: string.isRequired
}

export default VideoProvider
