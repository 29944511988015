const createQuery = () => `
  ... on Widget {
    id
    type
    displayOptions
    essential
    titleColor
    copyColor
  }
`
export default createQuery
