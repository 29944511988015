import React from 'react'
import { array, string } from 'prop-types'

import Container from '@/component/Primitive/Container'
import Faq from '@/component/Primitive/Faq'
import FaqList from '@/component/Primitive/FaqList'
import Stack from '@/component/Primitive/Stack'
import Type from '@/component/Primitive/Type'
import Prose from '@/component/Primitive/Prose'

const FaqWidget = ({ title, description, faqs, titleColor, copyColor }) => {
  return (
    <Container center gutter size="mediumLarge">
      <Stack gap="large">
        <Stack>
          {title && (
            <Type as="h2" size="heading2" color={titleColor || 'purple'}>
              {title}
            </Type>
          )}
          {description && (
            <Prose color={copyColor} dangerousHtml={description} />
          )}
        </Stack>
        <FaqList>
          {faqs?.map((faq, i) => {
            return (
              <Faq
                key={`faq:${i}`}
                question={faq.question}
                answer={faq.answer}
                titleColor={titleColor}
                copyColor={copyColor}
              />
            )
          })}
        </FaqList>
      </Stack>
    </Container>
  )
}

FaqWidget.propTypes = {
  title: string,
  description: string,
  faqs: array,
  titleColor: string,
  copyColor: string
}

export default FaqWidget
