import React, { useState } from 'react'
import { bool, string } from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './Faq.module.scss'

import Icon from '../Icon'
import Prose from '../Prose'
import ShrinkWrap from '../ShrinkWrap'
import Type from '../Type'

const Faq = ({
  question,
  answer,
  id,
  initialExpanded,
  titleColor,
  copyColor
}) => {
  const [expanded, toggleExpanded] = useState(initialExpanded)
  const { t } = useTranslation()

  const handleToggleExpanded = () => {
    toggleExpanded(!expanded)
  }

  return (
    <li
      className={classNames(styles.Faq, expanded && styles.expanded)}
      {...(id && { id })}
    >
      <button className={styles.FaqQuestion} onClick={handleToggleExpanded}>
        <ShrinkWrap fullWidth vAlign="middle">
          <ShrinkWrap.Item>
            <Type as="h3" size="heading4" color={titleColor || 'purple'}>
              {question}
            </Type>
          </ShrinkWrap.Item>
          <ShrinkWrap.Item shrink>
            <Icon
              type="expand-more"
              a11yText={expanded ? t('FaqHideAnswer') : t('FaqRevealAnswer')}
              className={styles.FaqIndicator}
            />
          </ShrinkWrap.Item>
        </ShrinkWrap>
      </button>
      {expanded && (
        <div className={styles.FaqAnswer}>
          <div className={styles.FaqAnswerInner}>
            <Prose color={copyColor} dangerousHtml={answer} />
          </div>
        </div>
      )}
    </li>
  )
}

Faq.propTypes = {
  question: string.isRequired,
  answer: string.isRequired,
  id: string,
  initialExpanded: bool,
  titleColor: string,
  copyColor: string
}

export default Faq
