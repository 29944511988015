import React from 'react'
import { array, bool, string } from 'prop-types'

import EmbellishedCardGrid from '@/component/Composition/EmbellishedCardGrid'

const ArticleGrid = ({
  articles,
  ctaLink,
  ctaText,
  inlineMobile,
  subtitle,
  title,
  titleColor,
  copyColor
}) => {
  const formattedArticles = articles?.map((article) => ({
    id: article._id,
    title: article.headline,
    sell: article.sell,
    link: article.fullUrlPath,
    // eslint-disable-next-line camelcase
    src: article.images?.thumbnail_16x9_310?.[0]?.url
  }))

  return (
    <EmbellishedCardGrid
      items={formattedArticles}
      title={title}
      subtitle={subtitle}
      ctaText={ctaText}
      ctaLink={ctaLink}
      inlineMobile={inlineMobile}
      titleColor={titleColor}
      copyColor={copyColor}
    />
  )
}

ArticleGrid.propTypes = {
  articles: array,
  ctaLink: string,
  ctaText: string,
  inlineMobile: bool,
  subtitle: string,
  title: string,
  titleColor: string,
  copyColor: string
}

export default ArticleGrid
